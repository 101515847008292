import { OutlinedInput, Theme } from '@mui/material';
import { HTMLInputTypeAttribute } from 'react';
import { Field } from 'formik';
import InputWrapper from '../InputWrapper';
import { important } from '../../../../utils/global';

interface TextInputProps {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
}

function TextInput({
  name,
  label,
  placeholder,
  disabled,
  type,
}: TextInputProps) {
  return (
    <InputWrapper label={label} name={name}>
      <Field
        id={name}
        type={type}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        as={OutlinedInput}
        onBlur={undefined}
        sx={(theme: Theme) => ({
          height: important('44px'),
          fontSize: '0.875rem',
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: important('0.5px'),
            borderColor: important(theme.palette.inputGreyOutline.main),
            borderRadius: important('10px'),
          },
        })}
      />
    </InputWrapper>
  );
}

export default TextInput;
