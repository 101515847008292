/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { getDate, getMonth, getYear } from 'date-fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ConfirmationResult } from 'firebase/auth';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  getCountryCallingCode,
  isPossiblePhoneNumber,
  Country,
} from 'react-phone-number-input';
import Box from '@mui/material/Box';
import BottomActionBar from '../../Global/BottomActionBar';
import { PiercingFormValues, Service } from '../../../interfaces';
import useSignIn from '../../../hooks/global/useSignIn';
import AuthCode from '../../auth/AuthCode';
import ResendCode from '../../auth/ResendCode';

type Props = {
  formValues: PiercingFormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  authUser: any;
  service: Service;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),

  firstName: yup.string().required('Name is required'),
  lastName: yup.string().required('Name is required'),

  dob: yup.date().required('Birthday is required'),
});

function PersonalInfo({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  authUser,
  service,
}: Props) {
  const [month, setMonth] = React.useState<string>('');
  const [year, setYear] = React.useState<string>('');
  const [day, setDay] = React.useState<string>('');

  const [value, setValue] = React.useState<string>();
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [userPhone, setUserPhone] = React.useState<string>();
  const [selectedCountry, setSelectedCountry] = React.useState<Country>('US');

  const [codeResult, setCodeResult] = React.useState('');
  const [codeError, setCodeError] = React.useState(false);
  const [loginStep, setLoginStep] = React.useState(1);
  const [confirmationResult, setConfirmationResult] =
    React.useState<ConfirmationResult>();

  const valid = value && isPossiblePhoneNumber(value);
  const codeValid = !!codeResult && codeResult.length === 6;

  const signInMutation = useSignIn({
    onSuccess: response => {
      if ('error' in response) {
        setErrorMessage(response.error);
        setError(true);
        return;
      }
      setUserPhone(value);
      setConfirmationResult(response.confirmation);
      setLoginStep(2);
      setError(false);
    },
  });

  const sendCode = React.useCallback(async () => {
    signInMutation.mutate(value);
  }, [signInMutation, value]);

  const questionValues = {
    header: 'Enter your information',
  };

  const formik = useFormik({
    initialValues: {
      email: formValues.email,
      dob: formValues.dob,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('email', values.email!);
      sessionStorage.setItem('dob', JSON.stringify(values.dob));

      sessionStorage.setItem('firstName', values.firstName!);
      sessionStorage.setItem('lastName', values.lastName!);

      nextStep();
    },
  });

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#333333',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding: '20px',
    },
  }));

  React.useEffect(() => {
    if (formValues.dob) {
      setMonth(JSON.stringify(getMonth(formValues.dob)));
      setYear(JSON.stringify(getYear(formValues.dob)));
      setDay(JSON.stringify(getDate(formValues.dob)));
    }
  }, [formValues.dob]);

  const setFormikValue = (
    newMonth: string | undefined,
    newYear: string | undefined,
    newDay: string | undefined,
  ) => {
    if (newMonth && newYear && newDay) {
      formik.setFieldValue(
        'dob',
        new Date(
          parseInt(newYear, 10),
          parseInt(newMonth, 10) - 1,
          parseInt(newDay, 10),
        ),
      );
      formik.setFieldTouched('dob', true, false);
    }
  };

  const changeMonth = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setMonth(event.target.value);
    setFormikValue(event.target.value, year, day);
  };

  const changeYear = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setYear(event.target.value);
    if (event.target.value.length === 4) {
      setFormikValue(month, event.target.value, day);
    }
  };

  const changeDay = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDay(event.target.value);
    setFormikValue(month, year, event.target.value);
  };

  const handleChange = React.useCallback((val: string) => {
    setValue(val);
  }, []);

  const handleCodeChange = (res: string) => {
    setCodeResult(res);
  };

  const authenticate = React.useCallback(async () => {
    if (!confirmationResult) return;
    confirmationResult.confirm(codeResult).catch(() => {
      setCodeError(true);
    });

    formik.submitForm();
  }, [confirmationResult, codeResult, formik]);

  const codeErrorMessage = (
    <Typography variant="h3" color="primary" className="error">
      Code could not be verified. Please try again
    </Typography>
  );

  return (
    <form onSubmit={formik.handleSubmit} id="final-details">
      <div className="final-details-container">
        {loginStep === 2 && (
          <div className="verify-code">
            <Box className="form-header-title">
              <Typography variant="h2" className="form-header">
                What&apos;s the code?
              </Typography>
              <Typography variant="body2" className="form-sub-header">
                Enter the code sent to <strong>{userPhone}</strong>
              </Typography>
            </Box>
            <Box className="input-container no-gap">
              <AuthCode value={codeResult} onChange={handleCodeChange} />
              <ResendCode
                phone={userPhone}
                onSuccess={response => {
                  setConfirmationResult(response.confirmation);
                }}
              />
            </Box>
            <BottomActionBar
              primaryText="Continue"
              primaryAction={authenticate}
              primaryDisabled={!codeValid}
            />
            {codeError ? codeErrorMessage : null}
          </div>
        )}
        {loginStep === 1 && (
          <div>
            <Box className="form-header-title">
              <Typography variant="h2" className="form-header">
                {questionValues.header}
              </Typography>
            </Box>
            <Box
              className="multi-input-container"
              style={{ padding: '0px 16px' }}>
              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                type="text"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <TextField
                id="lastName"
                name="lastName"
                label="Last Name"
                type="text"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              {!authUser && (
                <div className="phone-input-container">
                  <span className="country-code">
                    +{getCountryCallingCode(selectedCountry)}
                  </span>
                  <PhoneInput
                    defaultCountry="US"
                    onCountryChange={country => {
                      if (country) {
                        setSelectedCountry(country);
                      }
                    }}
                    value={value}
                    onChange={handleChange}
                    countrySelectProps={{ unicodeFlags: true }}
                    placeholder="Phone number"
                  />
                </div>
              )}
              {service.serviceType !== 'BABY_PIERCING' && (
                <div className="birthday-input">
                  <div className="birthday-header">
                    <FormLabel className="birthday-label">Birthday</FormLabel>
                    <LightTooltip
                      title="To submit this request, you must be at least 18 years old with a valid ID"
                      arrow
                      id="birthday-tooltip"
                      placement="bottom-start">
                      <IconButton>
                        <HelpOutlineOutlinedIcon />
                      </IconButton>
                    </LightTooltip>
                  </div>
                  <div className="birthday-inputs">
                    <TextField
                      id="month"
                      name="month"
                      className="month-input"
                      label="MM"
                      type="text"
                      value={month}
                      onChange={changeMonth}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        maxLength: 2,
                      }}
                    />
                    <TextField
                      id="day"
                      name="day"
                      className="day-input"
                      label="DD"
                      type="text"
                      value={day}
                      onChange={changeDay}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        maxLength: 2,
                      }}
                    />
                    <TextField
                      id="year"
                      name="year"
                      className="year-input"
                      label="YYYY"
                      type="text"
                      value={year}
                      onChange={changeYear}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        maxLength: 4,
                        minLength: 4,
                      }}
                    />
                  </div>
                </div>
              )}
            </Box>
            {authUser && (
              <BottomActionBar
                primaryText="Review"
                isSubmit
                primaryDisabled={!formik.isValid}
                secondaryText="Back"
                secondaryAction={prevStep}
              />
            )}
            {!authUser && !signInMutation.isPending && (
              <BottomActionBar
                primaryText="Continue"
                primaryAction={sendCode}
                primaryDisabled={
                  !formik.isValid || signInMutation.isPending || !valid
                }
                secondaryText="Back"
                secondaryAction={prevStep}
              />
            )}
          </div>
        )}
        {formik.errors.dob && formik.touched.dob ? (
          <Typography variant="body1" className="error">
            {formik.errors.dob}
          </Typography>
        ) : null}
        {formik.errors.email && formik.touched.email ? (
          <Typography variant="body1" className="error">
            {formik.errors.email}
          </Typography>
        ) : null}
        {error ? (
          <Typography variant="body1" color="primary" className="error">
            {errorMessage}
          </Typography>
        ) : null}
      </div>
    </form>
  );
}

export default PersonalInfo;
