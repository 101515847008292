import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './Form.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { FormValues, Choice, Employee } from '../../interfaces';
import { getChoices } from '../../services/choices';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  employee: Employee | undefined;
};

const validationSchema = yup.object({
  color: yup.string().required('Please choose an option'),
});

function Color({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  employee,
}: Props) {
  const [colors, setColors] = React.useState<Choice[]>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchData() {
      let tempColors = await getChoices('TATTOO_COLOR').catch(() => {});
      tempColors.sort((a: Choice, b: Choice) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });

      if (
        employee?.employeeId === 'clr8yw3jd000bs60utg1q2sh1' ||
        employee?.employeeId === 'clcrrrq2r0004s60ogkae1wvf' ||
        employee?.employeeId === 'clwa2vh1i009nzzmgpoxhr3yt' ||
        employee?.employeeId === 'cli0k4049000as60uhen7j6bj'
      ) {
        tempColors = tempColors.filter(
          (tempColor: any) => tempColor.name !== 'Color',
        );
      }

      if (
        employee?.employeeId === 'clrs0uxje0046s60u224j6cx9' ||
        employee?.employeeId === 'clrtsa8450053s60umume1001'
      ) {
        tempColors.push({
          name: 'Blackout',
          value: 'Blackout',
        });

        tempColors.push({
          name: 'KawaiiCore Collab',
          value: 'KawaiiCore Collab',
        });
      }

      if (employee?.employeeId === 'clwa2vh1i009nzzmgpoxhr3yt') {
        tempColors.push({
          name: 'Black & Red Ink',
          value: 'Black & Red Ink',
        });
      }

      setColors(tempColors);
      setLoading(false);
    }
    if (loading) {
      fetchData();
    }
  });

  const questionValues = {
    header: 'What coloring do you want for your tattoo?',
  };

  const formik = useFormik({
    initialValues: {
      color: formValues.color,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('color', values.color!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="tattoo-color">
      <FormHeader header={questionValues.header} />
      <Box className="input-container">
        <RadioGroup
          aria-labelledby="radio-color"
          id="tattoo-color-radio-group"
          name="color"
          value={formik.values.color}
          onChange={formik.handleChange}
          className="radio-box">
          {loading ? (
            <Typography variant="inherit">Loading...</Typography>
          ) : null}
          {!loading && !colors ? (
            <Typography variant="inherit">
              Error loading tattoo color options
            </Typography>
          ) : null}
          {colors && colors.length > 0
            ? colors.map((color: Choice) => (
                <FormControlLabel
                  value={color.name}
                  key={color.name}
                  control={
                    <Radio
                      className="radio-input"
                      inputProps={{ className: 'radio-color' }}
                      color="secondary"
                    />
                  }
                  label={color.name}
                  className={`radio-pill ${
                    formik.values.color === color.name ? 'checked' : ''
                  }`}
                />
              ))
            : null}
        </RadioGroup>
        {formik.touched.color && formik.errors.color && (
          <FormHelperText className="error">
            {formik.errors.color}
          </FormHelperText>
        )}
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default Color;
