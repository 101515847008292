/* eslint-disable react/require-default-props */
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

type Props = {
  title?: string;
  subtitle?: string;
  step?: number;
};

function TopBar({ title, subtitle, step }: Props) {
  const theme = useTheme();
  const desktop = useMediaQuery('(min-width:600px)');

  const openBeacon = () => window.Beacon('open');

  return (
    <Box
      style={{
        display: step === 1 ? 'none' : 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 16px 10px 16px',
        position: 'fixed',
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.lightGrey.main,
        borderBottomStyle: 'solid',
        backgroundColor: '#ffffff',
        width: !desktop ? 'calc(100% - 32px)' : 568,
        zIndex: 2,
      }}>
      {subtitle && (
        <Typography variant="h4" color={theme.palette.outlineGrey.main}>
          {subtitle}
        </Typography>
      )}
      {title && <Typography variant="h3">{title}</Typography>}
      <HelpOutlineIcon
        onClick={openBeacon}
        htmlColor={theme.palette.darkGrey.main}
        style={{
          position: 'absolute',
          right: 16,
          bottom: 10,
        }}
      />
    </Box>
  );
}

export default TopBar;
