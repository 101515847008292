import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import useGetBusiness from '../hooks/businesses/useGetBusiness';
import Waiver from '../components/Booking/Waiver/Waiver';
import TopBar from '../components/Global/TopBar';
import useUser from '../hooks/global/useUser';

export default function PreviewWaiverPage() {
  const { shopUrl } = useParams();

  const { isLoading } = useUser();
  const { business } = useGetBusiness(shopUrl);

  return (
    <Box
      style={{
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        margin: 'auto',
      }}>
      <TopBar title="Waiver Preview" subtitle="Your Appointment" />
      <Box
        style={{
          flex: '1 1 0',
          marginTop: 63,
          paddingBottom: 165,
        }}>
        {business && !isLoading && (
          <Waiver
            type="preview"
            businessId={business?.businessId}
            business={business}
          />
        )}
        {(!business || isLoading) && (
          <Box
            height={300}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>
    </Box>
  );
}
