import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import useStateCooldown from '../../hooks/useStateCooldown';
import useSignIn from '../../hooks/global/useSignIn';
import { SignInSuccessResult } from '../../types/auth';

interface Props {
  phone: string | undefined;
  onSuccess?: (response: SignInSuccessResult) => void;
  containerSx?: SxProps<Theme>;
}

function ResendCode({ phone, onSuccess, containerSx }: Props) {
  const [disabledResend, setDisabledResend] = useStateCooldown(30_000, true);

  const resendMutation = useSignIn({
    onSuccess: response => {
      if ('error' in response) {
        toast.error('Failed to resend code. Please try again.');
        return;
      }

      onSuccess?.(response);
      toast.success('Code has been re-sent');

      setDisabledResend(true);
    },
  });

  const resend = async () => {
    resendMutation.mutate(phone);
  };

  return (
    <Box sx={containerSx}>
      <Typography display="inline" variant="body2" mr={0.5}>
        Didn&apos;t get a code?{' '}
      </Typography>
      <LoadingButton
        onClick={resend}
        disableRipple
        disabled={disabledResend || resendMutation.isPending}
        variant="link"
        sx={theme => ({
          my: 3,
          color: theme.palette.primary.main,
          minWidth: 0,
          mx: 'auto',
        })}>
        <span>Resend Code</span>
      </LoadingButton>
    </Box>
  );
}

export default ResendCode;
