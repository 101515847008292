import React from 'react';
import { OutlinedInput, Stack, Theme, Typography } from '@mui/material';
import { important } from '../../utils/global';

interface Props {
  label?: string;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const CODE_LENGTH = 6;

function AuthCode({
  label = 'Enter Code',
  disabled,
  value,
  onChange: onChangeText,
}: Props) {
  const changeHandler = (val: string) => {
    const code = val.replace(/\D/g, '').slice(0, CODE_LENGTH);
    onChangeText(code);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeHandler(event.target.value);
  };

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    changeHandler(event.clipboardData.getData('text').replace(/\D/g, ''));
  };

  const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value: val } = event.target;
    changeHandler(val);
  };

  return (
    <Stack gap={1}>
      {label && (
        <Typography variant="body2" fontWeight="700">
          {label}
        </Typography>
      )}
      <OutlinedInput
        autoFocus
        type="text"
        disabled={disabled}
        sx={(theme: Theme) => ({
          height: important('50px'),
          fontSize: '0.875rem',
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: important('10px'),
            borderWidth: important('0.5px'),
            borderColor: important(theme.palette.inputGreyOutline.main),
          },
          '&:focus-within .MuiOutlinedInput-notchedOutline': {
            borderColor: important(theme.palette.primary.main),
          },
        })}
        value={value}
        onChange={onChange}
        onPaste={onPaste}
        onInput={onInput}
        autoComplete="off"
        inputMode="numeric"
        inputProps={{
          pattern: '[0-9]*',
          maxLength: 6,
          inputMode: 'numeric',
        }}
      />
    </Stack>
  );
}

export default AuthCode;
