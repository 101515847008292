/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
} from '@mui/base/Unstable_NumberInput';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import '../Form.css';
import Box from '@mui/material/Box';
import { PiercingFormValues } from '../../../interfaces';
import BottomActionBar from '../../Global/BottomActionBar';

type Props = {
  formValues: PiercingFormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
};

const StyledInputRoot = styled('div')(
  () => `
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: var(--darkGrey);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`,
);

const StyledInput = styled('input')(
  () => `
  font-size: 1.125rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: var(--darkGrey);
  background: #fff;
  border: 1px solid var(--outlineGrey);
  border-radius: 5px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;

  &:hover {
    border-color: var(--darkGrey);
  }

  &:focus {
    border-color: var(--darkGrey);
  }

  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledButton = styled('button')(
  () => `
  font-family: 'Montserrat', sans-serif;
  font-size: 1.125rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  border-color: var(--outlineGrey);
  background: var(--lightGrey);
  color: var(--darkGrey);
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`,
);

const CustomNumberInput = React.forwardRef(
  (props: NumberInputProps, ref: React.ForwardedRef<HTMLDivElement>) => (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon fontSize="small" />,
          className: 'increment',
        },
        decrementButton: {
          children: <RemoveIcon fontSize="small" />,
        },
      }}
      {...props}
      ref={ref}
    />
  ),
);

function Guests({ formValues, setFormValues, nextStep, prevStep }: Props) {
  const [value, setValue] = React.useState<number>(1);

  React.useEffect(() => {
    if (formValues.numberOfGuests) {
      setValue(formValues.numberOfGuests);
    }
  }, [formValues.numberOfGuests]);

  const questionValues = {
    header: `How many are in your party?`,
    subHeader:
      'If you’re scheduling for more than one person to be pierced, add them here',
  };

  const submit = () => {
    setFormValues({
      ...formValues,
      numberOfGuests: value,
    });
    sessionStorage.setItem('numberOfGuests', JSON.stringify(value));
    nextStep();
  };

  return (
    <form>
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header sub">
          {questionValues.header}
        </Typography>
        <Typography variant="body2" className="form-header">
          {questionValues.subHeader}
        </Typography>
      </Box>

      <Box className="input-container">
        <CustomNumberInput
          value={value}
          onChange={(event, val) => {
            event.preventDefault();
            if (val) {
              setValue(val);
            }
          }}
          min={1}
          max={5}
          placeholder="Number of guests"
        />
      </Box>
      <BottomActionBar
        primaryText="Continue"
        primaryAction={submit}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default Guests;
