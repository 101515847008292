import {
  FormControlLabel,
  Grid,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import { Service } from '../../interfaces';

interface Props {
  service: Service;
  onServiceClick: (service: Service) => void; // Add this line
}

export default function ServiceCard({ service, onServiceClick }: Props) {
  const theme = useTheme();

  const handleClick = () => {
    onServiceClick(service); // Call the click handler with the service object
  };

  const createLabel = () => (
    <div className="service-label">
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Typography variant="h3">{service.serviceName}</Typography>
        <Typography variant="body2" color={theme.palette.medGrey.main}>
          {service.serviceDescription}
        </Typography>
      </div>
    </div>
  );

  return (
    <Grid item xs={12} onClick={handleClick}>
      <FormControlLabel
        value={service.serviceId}
        control={<Radio inputProps={{ className: 'radio-service' }} />}
        label={createLabel()}
        className="service-label"
        labelPlacement="top"
      />
    </Grid>
  );
}
