/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Box, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useIdToken } from 'react-firebase-hooks/auth';
import { Employee, User } from '../interfaces';
import Form from '../components/Form/Form';
import { auth } from '../firebase';
import { getUser } from '../services/user';
import { getEmployeeByUrlPath } from '../services/employee';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import ProgressBar from '../components/EmployeeHeader/ProgressBar';

const tempAppointmentCity: string =
  sessionStorage.getItem('city') !== null
    ? sessionStorage.getItem('city')!
    : '';

const tempAppointmentTimezone: string =
  sessionStorage.getItem('timezone') !== null
    ? sessionStorage.getItem('timezone')!
    : '';

export default function ArtistPage() {
  const desktop = useMediaQuery('(min-width:600px)');

  const [employee, setEmployee] = React.useState<Employee>();
  const [user, setUser] = React.useState<User>();

  const [progress, setProgress] = React.useState('tattoo details');
  const [step, setStep] = React.useState(1);

  const [loading, setLoading] = React.useState(true);

  const [userError, setUserError] = React.useState(false);
  const { urlPath } = useParams();
  const [authUser, authLoading] = useIdToken(auth);
  const [employeeError, setEmployeeError] = React.useState(false);
  const [askCity, setAskCity] = React.useState(false);
  const [appointmentCity, setAppointmentCity] =
    React.useState(tempAppointmentCity);
  const [appointmentTimezone, setAppointmentTimezone] = React.useState(
    tempAppointmentTimezone,
  );

  React.useEffect(() => {
    if (sessionStorage.getItem('progress') !== null) {
      setProgress(sessionStorage.getItem('progress')!);
    }
  }, []);

  React.useEffect(() => {
    if (sessionStorage.getItem('step') !== null) {
      setStep(JSON.parse(sessionStorage.getItem('step')!));
    }
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      const tempEmployee = await getEmployeeByUrlPath(urlPath).catch(() => {
        setEmployeeError(true);
      });

      if (tempEmployee) {
        window._cio.page('Artist', {
          displayName: tempEmployee.displayName,
        });

        if (
          tempEmployee.trips &&
          tempEmployee.trips.length > 0 &&
          tempEmployee.trips.some(trip => trip.booksOpen)
        ) {
          setAskCity(true);
        }
        setEmployee({
          ...tempEmployee,
        });
      }

      setLoading(false);
    }

    if (loading) {
      fetchData();
    }
  }, [loading, urlPath]);

  React.useEffect(() => {
    async function fetchUserData() {
      const tempUser = await getUser().catch(() => {
        setUserError(true);
      });

      if (tempUser.userId) {
        const createdAt = new Date(tempUser.createdAt);
        window.heap.identify(tempUser.userId);
        window._cio.identify({
          // Required attributes — you must include at least one of the following
          id: tempUser.userId, // Unique id for the user in your application.
          email: tempUser.email, // Email of the currently signed in user.

          created_at: Math.floor(createdAt.getTime() / 1000),
          first_name: tempUser.firstName,
          last_name: tempUser.lastName,
          phone_number: tempUser.phoneNumber,
        });
      }

      setUser({
        ...tempUser,
      });
    }

    if (authUser && !authLoading) {
      fetchUserData();
    }
  }, [authUser, authLoading, employee]);

  switch (true) {
    // Everything is loading
    case loading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Loaded, no open request, no need to ask city
    case !loading && !employeeError:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
            backgroundColor:
              employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
                ? '#ebcbd2'
                : '#ffffff',
          }}>
          <TopBar
            title={employee?.displayName || employee?.user.firstName}
            subtitle="Booking Request"
            step={step}
          />
          <ProgressBar
            progress={progress}
            step={step}
            intakeQuestions={employee?.intakeQuestions}
          />
          <Form
            progress={progress}
            setProgress={setProgress}
            step={step}
            setStep={setStep}
            employee={employee}
            user={user}
            appointmentCity={appointmentCity}
            setAppointmentCity={setAppointmentCity}
            appointmentTimezone={appointmentTimezone}
            setAppointmentTimezone={setAppointmentTimezone}
            askCity={askCity}
            authUser={authUser}
            intakeQuestions={employee?.intakeQuestions}
          />
          {!desktop && <PorterFooter />}
        </Box>
      );

    case employeeError || userError:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this artist&apos;s page. Please double
            check that you&apos;ve entered the url in correctly, and if the
            error persists, reach out to our support team using the help icon
            below.
          </Typography>
        </Container>
      );

    default:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this artist&apos;s page. Please double
            check that you&apos;ve entered the URL in correctly, and if the
            error persists, reach out to our support team using the help icon
            below.
          </Typography>
        </Container>
      );
  }
}
