/* eslint-disable react/require-default-props */
import * as React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PaidIcon from '@mui/icons-material/Paid';
import MessageIcon from '@mui/icons-material/Message';
import CancelIcon from '@mui/icons-material/Cancel';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Appointment, Business, Employee, Service } from '../../../interfaces';
import {
  getAptStartEnd,
  getFullPriceText,
  getLengthText,
  getPiercingPriceText,
} from '../../../utils/appointment';
import { createAppointmentChat } from '../../../services/appointment';

type Props = {
  appointment?: Appointment | undefined;
  service?: Service;
  duration?: number;
  piercingPrice?: number;
  employee: Employee | undefined;
  business?: Business | undefined;
  aptDate?: Date;
  location?: boolean;
  serviceType?: boolean;
  length?: boolean;
  price?: boolean;
  designMessage?: boolean;
  appointmentDate?: boolean;
  cancellation?: boolean;
  changeDateAction?: () => void;
  changeDateText?: string;
  isReschedule?: boolean;
};

export default function AppointmentDetailsList({
  appointment,
  service,
  duration,
  piercingPrice,
  employee,
  business,
  aptDate,
  location,
  serviceType,
  length,
  price,
  designMessage,
  appointmentDate,
  cancellation,
  changeDateAction,
  changeDateText,
  isReschedule,
}: Props) {
  const theme = useTheme();
  const [studioName, setStudioName] = React.useState('');
  const [studioAddress, setStudioAddress] = React.useState('');
  const [cityStateZip, setCityStateZip] = React.useState('');

  React.useEffect(() => {
    if (appointment && employee) {
      const appointmentsTrip = employee.trips?.find(
        trip => trip.city === appointment.tripCity,
      );
      if (appointment.tripCity && appointmentsTrip) {
        if (appointmentsTrip.studioName) {
          setStudioName(appointmentsTrip.studioName);
        }

        if (appointmentsTrip.streetAddress) {
          let tempStreetAddress = appointmentsTrip.streetAddress;
          if (appointmentsTrip.streetAddress2) {
            tempStreetAddress += `, ${appointmentsTrip.streetAddress2}`;
          }
          setStudioAddress(tempStreetAddress);
        }

        setCityStateZip(
          `${appointmentsTrip.city}, ${appointmentsTrip.state} ${appointmentsTrip.zip}`,
        );
      } else if (business) {
        setStudioName(business.name);

        if (business.streetAddress) {
          let tempStreetAddress = business.streetAddress;
          if (business.streetAddress2) {
            tempStreetAddress += business.streetAddress2;
          }
          setStudioAddress(tempStreetAddress);
        }

        setCityStateZip(`${business.city}, ${business.state} ${business.zip}`);
      }
    } else if (employee && !appointment && service) {
      if (employee.business) {
        setStudioName(employee.business.name);

        if (employee.business.streetAddress) {
          let tempStreetAddress = employee.business.streetAddress;
          if (employee.business.streetAddress2) {
            tempStreetAddress += employee.business.streetAddress2;
          }
          setStudioAddress(tempStreetAddress);
        }

        setCityStateZip(
          `${employee.business.city}, ${employee.business.state} ${employee.business.zip}`,
        );
      }
    }
  }, [appointment, employee, service, business]);

  const openChat = React.useCallback(async () => {
    if (appointment) {
      const chatObject = await createAppointmentChat(appointment);
      if (chatObject) {
        window.location.href = `/chat/${chatObject.data.result.id}`;
      }
    }
  }, [appointment]);

  const depositPrice = isReschedule
    ? appointment?.depositPrice || appointment?.reschedulePrice
    : appointment?.depositPrice;

  return (
    <Box
      style={{
        padding: '0px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}>
      {location && (
        <Box className="schedule-detail-item">
          <LocationOnIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {studioName && (
              <Typography variant="body2">{studioName}</Typography>
            )}
            {studioAddress && (
              <Typography variant="body2">{studioAddress}</Typography>
            )}
            {cityStateZip && (
              <Typography variant="body2">{cityStateZip}</Typography>
            )}
          </Box>
        </Box>
      )}
      {length && (
        <Box className="schedule-detail-item">
          <WatchLaterIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {appointment?.appointmentLengthInMinutes && (
              <Typography variant="body2">
                {getLengthText(appointment.appointmentLengthInMinutes)}
              </Typography>
            )}
            {duration && !appointment && (
              <Typography variant="body2">{getLengthText(duration)}</Typography>
            )}
          </Box>
        </Box>
      )}
      {appointmentDate && (
        <Box className="schedule-detail-item">
          <WatchLaterIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {appointment?.appointmentLengthInMinutes && aptDate && (
              <Typography variant="body2">
                {getAptStartEnd(
                  aptDate,
                  appointment.appointmentLengthInMinutes,
                  appointment.timezone ||
                    employee?.user.timezone ||
                    'US/Pacific',
                )}
              </Typography>
            )}
            {duration && !appointment && aptDate && (
              <Typography variant="body2">
                {getAptStartEnd(
                  aptDate,
                  duration,
                  employee?.user.timezone || 'US/Pacific',
                )}
              </Typography>
            )}
            {changeDateAction && (
              <Button
                variant="text"
                style={{ fontSize: 14 }}
                className="chat-text-btn"
                onClick={changeDateAction}>
                {changeDateText}
              </Button>
            )}
          </Box>
        </Box>
      )}

      {price && (
        <Box className="schedule-detail-item">
          <PaidIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {appointment && appointment.priceType && (
              <Typography variant="body2">
                {getFullPriceText(
                  appointment.priceType,
                  depositPrice,
                  appointment.minPrice,
                  appointment.maxPrice,
                  appointment.hourlyPrice,
                  appointment.price,
                )}
              </Typography>
            )}
            {!appointment && (
              <Typography variant="body2">
                {piercingPrice
                  ? getPiercingPriceText(piercingPrice)
                  : 'Pricing varies based on service & jewelry selection'}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {serviceType && (
        <Box className="schedule-detail-item">
          <TextSnippetIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {service && (
              <Typography variant="body2">
                {service.serviceName} with {employee?.displayName}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {designMessage && (
        <Box className="schedule-detail-item">
          <MessageIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            {(appointment?.customDesignMessage ||
              employee?.standardDesignMessage) && (
              <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                {appointment?.customDesignMessage ||
                  employee?.standardDesignMessage}
              </Typography>
            )}
            <Button
              variant="text"
              style={{ fontSize: 14 }}
              className="chat-text-btn"
              id="open-chat-request"
              onClick={openChat}>
              Chat with{' '}
              {employee?.displayName ||
                `${employee?.user.firstName}` ||
                'your artist'}
            </Button>
          </Box>
        </Box>
      )}

      {cancellation && (
        <Box className="schedule-detail-item">
          <CancelIcon
            fontSize="medium"
            htmlColor={theme.palette.outlineGrey.main}
          />
          <Box>
            <Typography variant="body2">
              {employee?.cancellationPolicyText}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
