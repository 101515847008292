/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IntakeQuestion } from '../../interfaces';
import './ArtistHeader.css';

interface Props {
  progress: string;
  step: number;
  intakeQuestions: IntakeQuestion[] | undefined;
}

function ProgressBar({ progress, step, intakeQuestions }: Props) {
  const theme = useTheme();
  const [sections, setSections] = React.useState<String[]>();
  const [lineBreak, setLineBreak] = React.useState<number>();
  const [lineStyle, setLineStyle] = React.useState<any>();
  const [progNum, setProgNum] = React.useState<number>();

  React.useEffect(() => {
    if (intakeQuestions) {
      intakeQuestions!.sort((a: any, b: any) => a.step - b.step);
      const tempSections: String[] = [];

      intakeQuestions!.forEach((question: IntakeQuestion) => {
        if (!tempSections.includes(question.category)) {
          tempSections.push(question.category);
        }
      });

      setSections(tempSections);
    }
  }, [intakeQuestions]);

  React.useEffect(() => {
    if (sections && intakeQuestions) {
      const tempProgNum = sections.indexOf(progress);
      let tempLineBreak = 0;

      if (tempProgNum === sections.length - 1) {
        tempLineBreak = 100;
      } else if (tempProgNum === 0) {
        tempLineBreak = 0;
      } else {
        tempLineBreak = (100 / (sections.length - 1)) * tempProgNum;
      }

      const questionsCategories = intakeQuestions!.filter(
        question => question.category === progress,
      );
      const currentIndex = questionsCategories.findIndex(
        question => question.step === step,
      );
      const baseUnit = 100 / questionsCategories.length;
      const amountToAdd = baseUnit * currentIndex;
      const percentToAdd = (amountToAdd * (100 / (sections.length - 1))) / 100;

      if (percentToAdd) {
        tempLineBreak += percentToAdd;
      }

      const tempLineStyle = {
        background: `linear-gradient(to right, ${theme.palette.accent.main} 0%, ${theme.palette.accent.main} ${lineBreak}%, ${theme.palette.lightGrey.main} ${lineBreak}%, ${theme.palette.lightGrey.main} 100%`,
      };

      setProgNum(tempProgNum);
      setLineBreak(tempLineBreak);
      setLineStyle(tempLineStyle);
    }
  }, [intakeQuestions, lineBreak, progress, sections, step, theme.palette]);

  return (
    <Box className="progress-bar" hidden={step === 1}>
      <Box className="progress-steps">
        {sections?.map((section, index) => (
          <Box className="step">
            <div
              className={`step-circle ${
                (progNum || progNum === 0) && progNum >= index ? 'done' : ''
              }`}>
              <Typography variant="h4" color="darkGrey">
                {index + 1}
              </Typography>
            </div>
            <div className="step-label">
              <Typography variant="body2">
                {section === 'TATTOO_DETAILS'
                  ? 'Tattoo'
                  : section === 'GENERAL'
                  ? 'Your Info'
                  : section === 'IMAGES'
                  ? 'Photos'
                  : 'Review'}
              </Typography>
            </div>
          </Box>
        ))}
      </Box>

      <div className="progress-line" style={lineStyle} />
    </Box>
  );
}

export default ProgressBar;
