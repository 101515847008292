/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import '../Form.css';
import Box from '@mui/material/Box';
import {
  Business,
  Employee,
  PiercingFormValues,
  Service,
} from '../../../interfaces';
import BottomActionBar from '../../Global/BottomActionBar';
import ArtistRow from '../../BusinessHeader/ArtistRow';

type Props = {
  formValues: PiercingFormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  service: Service;
  business: Business | undefined;
};

function ChoosePiercer({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  service,
  business,
}: Props) {
  const [employees, setEmployees] = React.useState<Employee[]>();
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    if (
      business &&
      service &&
      service.employeesToServices &&
      business.employees
    ) {
      // Extracting employeeIds from the service object
      const employeeIdsInService = service.employeesToServices.map(
        es => es.employeeId,
      );

      // Filtering the business employees array
      const employeesInService = business.employees.filter(emp =>
        employeeIdsInService.includes(emp.employeeId),
      );

      setEmployees(employeesInService);
    } else {
      setError('Cannot find piercers for this service');
    }
  }, [business, service]);

  const questionValues = {
    header: 'Choose your piercer',
  };

  const handleClick = (employeeId: string | undefined) => {
    const values = {
      employeeId,
    };

    setFormValues({
      ...formValues,
      ...values,
    });

    if (employeeId) {
      sessionStorage.setItem('employeeId', values.employeeId!);
    }

    nextStep();
  };

  return (
    <div>
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header">
          {questionValues.header}
        </Typography>
      </Box>
      {business && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            marginLeft: 16,
            marginRight: 16,
          }}>
          {employees?.map(employee => (
            <ArtistRow
              key={employee.employeeId}
              employee={employee}
              handleClick={handleClick}
            />
          ))}
        </Box>
      )}

      {!business && error && <Typography variant="body1">{error}</Typography>}

      <BottomActionBar secondaryText="Back" secondaryAction={prevStep} />
    </div>
  );
}

export default ChoosePiercer;
