import { useQuery } from '@tanstack/react-query';
import { getBusinessByUrlPath } from '../../services/business';

function useGetBusiness(path: string | undefined) {
  const {
    data: business,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ['business-path', path],
    queryFn: async () => {
      const tempBusiness = await getBusinessByUrlPath(path!);
      return tempBusiness || null;
    },
    enabled: !!path,
  });

  return {
    business,
    isLoading,
    isFetching,
    isError,
    error,
  };
}

export default useGetBusiness;
