import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from 'react-phone-number-input';

export function important<T>(value: T): T {
  return `${value} !important` as any;
}

export function formatPhone(phoneNumber: string) {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  const formattedPhoneNumber = (
    parsedPhoneNumber?.countryCallingCode === '1'
      ? formatPhoneNumber
      : formatPhoneNumberIntl
  )(phoneNumber);

  return formattedPhoneNumber;
}

export function isNil<T>(
  value: T | null | undefined,
): value is null | undefined {
  return value === null || value === undefined;
}
