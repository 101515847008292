import { useQuery } from '@tanstack/react-query';
import { Appointment, AppointmentCheckoutSession } from '../../interfaces';
import { getAppointmentCheckoutSession } from '../../services/appointment';

function useCheckoutSession({
  appointment,
  businessId,
  tipAmountCents,
  onSuccess,
  enabled = true,
}: {
  appointment: Appointment;
  tipAmountCents: number;
  businessId: string | undefined;
  onSuccess?: (checkoutSession: AppointmentCheckoutSession) => void;
  enabled?: boolean;
}) {
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'checkoutSession',
      appointment.appointmentId,
      tipAmountCents,
      businessId,
    ],
    queryFn: async () => {
      if (!appointment.appointmentId) return null;
      const result = await getAppointmentCheckoutSession(
        appointment.appointmentId,
        tipAmountCents,
        businessId,
      );
      if (result) {
        onSuccess?.(result);
      }
      return result;
    },
    enabled: !!appointment?.appointmentId && enabled,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  return {
    checkoutSession: data,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  };
}

export default useCheckoutSession;
