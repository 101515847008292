import * as React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { Business, Employee, FormValues, Image } from '../../interfaces';
import ImageUpload from './ImageUpload';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  employee: Employee | undefined;
  business: Business | undefined;
};

function RefImages({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  employee,
  business,
}: Props) {
  const [images, setImages] = React.useState<Image[]>([]);
  const [isValid, setIsValid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const [isRequired, setIsRequired] = React.useState(true);

  React.useEffect(() => {
    if (
      employee?.business?.businessId === 'clo8xb4tc002us60u9cm3l4mf' ||
      business?.businessId === 'clo8xb4tc002us60u9cm3l4mf'
    ) {
      setIsRequired(false);
    }
  }, [employee, business]);

  React.useEffect(() => {
    if (images.length > 0 && images.length <= 6) {
      setIsValid(true);
    }
  }, [images]);

  React.useEffect(() => {
    if (loading) {
      if (formValues.refImages && formValues.refImages.length > 0) {
        setImages([...formValues.refImages]);
        setIsValid(true);
      }
      setLoading(false);
    }
  }, [formValues.refImages, loading]);

  const questionValues = {
    header: 'Last step! Add reference images',
    subHeader: 'Share the inspiration for your tattoo design',
  };

  if (employee?.employeeId === 'clp1tilea0000s60u1xlhc0jc') {
    questionValues.subHeader =
      'Please upload at least three reference images of your idea. Also feel free to include a photo of the area you would like tattooed on yourself so I can design around your specific body shape. Photos should be taken in good lighting, about 2 feet away from the subject.';
  }

  if (
    employee?.employeeId === 'clix99ce5001ks60uygdf0xcr' ||
    employee?.employeeId === 'clnc519vx004as60u93uy6pvc'
  ) {
    questionValues.header = 'Almost done! Add reference images';
  }

  const saveImages = () => {
    if (isRequired && !isValid) {
      return;
    }

    if ((images.length < 1 || images.length > 6) && isRequired) {
      setIsValid(false);
      setError('Please upload at least 1 image and no more than 6.');
      return;
    }

    // Set images as the formValue item
    const values = {
      refImages: images,
    };

    setFormValues({
      ...formValues,
      ...values,
    });

    images.forEach((image, index) => {
      sessionStorage.setItem(`refImage-${index}`, JSON.stringify(image));
    });
    sessionStorage.setItem(`refImageCount`, JSON.stringify(images.length));

    nextStep();
  };

  return (
    <form id="ref-images">
      <FormHeader
        header={questionValues.header}
        subHeader={questionValues.subHeader}
      />
      <Box className="input-container">
        <ImageUpload
          images={images}
          setImages={setImages}
          setIsValid={setIsValid}
        />
      </Box>
      <BottomActionBar
        primaryText="Continue"
        primaryAction={saveImages}
        primaryDisabled={isRequired && !isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
      {error && <FormHelperText className="error">{error}</FormHelperText>}
    </form>
  );
}

export default RefImages;
