import * as React from 'react';
import { Box } from '@mui/material';
import BottomActionBar from './BottomActionBar';
import FormHeader, { FormHeaderAlert } from '../Form/FormHeader';

type Props = {
  header: string;
  children: React.ReactNode;
  subheader?: string;
  onNext?: () => void;
  onBack?: () => void;
  nextDisabled?: boolean;
  isNextSubmit?: boolean;
  isLoadingNext?: boolean;
  alert?: FormHeaderAlert;
};

function PageLayout({
  header,
  subheader,
  children,
  onNext,
  onBack,
  nextDisabled,
  isNextSubmit,
  isLoadingNext,
  alert,
}: Props) {
  return (
    <Box>
      <FormHeader header={header} subHeader={subheader} alert={alert} />
      <Box
        sx={{
          width: 'calc(100% - 32px)',
          maxWidth: 600,
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '0px 16px',
        }}>
        {children}
      </Box>
      <BottomActionBar
        isLoading={isLoadingNext}
        primaryText={isNextSubmit ? 'Submit' : 'Next'}
        primaryAction={onNext}
        secondaryAction={onBack}
        secondaryText="Back"
        primaryDisabled={nextDisabled}
      />
    </Box>
  );
}

export default PageLayout;
