/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import '../Form.css';
import Box from '@mui/material/Box';
import { PiercingFormValues, PiercingLocation } from '../../../interfaces';
import BottomActionBar from '../../Global/BottomActionBar';
import { getPiercingLocations } from '../../../services/choices';

type Props = {
  formValues: PiercingFormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
};

const validationSchema = yup.object({
  piercingLocationCategory: yup
    .string()
    .required('Please select a piercing location'),
  piercingLocationId: yup
    .string()
    .required('Please select a piercing location'),
});

function SelectPiercingLocation({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
}: Props) {
  const [allLocations, setAllLocations] = React.useState<PiercingLocation[]>();
  const [categories, setCategories] = React.useState<string[]>();
  const [types, setTypes] = React.useState<PiercingLocation[]>();
  const [loading, setLoading] = React.useState(true);

  const questionValues = {
    header: 'Where on your body do you want your piercing?',
  };

  React.useEffect(() => {
    async function fetchData() {
      const tempLocations = await getPiercingLocations().catch(() => {});

      // Extracting unique locationCategory values
      const uniqueLocationCategories: string[] = Array.from(
        new Set(
          tempLocations.map((item: PiercingLocation) => item.locationCategory),
        ),
      );
      setAllLocations(tempLocations);
      setCategories(uniqueLocationCategories);
      setLoading(false);
    }
    if (loading) {
      fetchData();
    }
  });

  const formik = useFormik({
    initialValues: {
      piercingLocationCategory: formValues.piercingLocationCategory,
      piercingLocationId: formValues.piercingLocationId,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem(
        'piercingLocationCategory',
        values.piercingLocationCategory!,
      );
      // @ts-expect-error
      sessionStorage.setItem('piercingLocationId', values.piercingLocationId!);
      nextStep();
    },
  });

  React.useEffect(() => {
    if (categories && formik.values.piercingLocationCategory) {
      const tempTypes = allLocations?.filter(
        location =>
          location.locationCategory === formik.values.piercingLocationCategory,
      );
      setTypes(tempTypes);
    }
  }, [allLocations, categories, formik.values.piercingLocationCategory]);

  return (
    <form onSubmit={formik.handleSubmit} id="tattoo-location">
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header">
          {questionValues.header}
        </Typography>
      </Box>

      <Box className="input-container">
        {loading ? <Typography variant="inherit">Loading...</Typography> : null}
        {!loading && !categories ? (
          <Typography variant="inherit">
            Error loading piercing locations
          </Typography>
        ) : null}
        {categories && categories.length > 0 ? (
          <TextField
            id="piercingLocationCategory"
            select
            name="piercingLocationCategory"
            label="Piercing Location"
            value={formik.values.piercingLocationCategory}
            onChange={(e: React.ChangeEvent<any>) => {
              formik.setFieldValue('piercingLocationId', '');
              formik.handleChange(e);
            }}
            error={
              formik.touched.piercingLocationCategory &&
              Boolean(formik.errors.piercingLocationCategory)
            }
            helperText={
              formik.touched.piercingLocationCategory &&
              formik.errors.piercingLocationCategory
            }>
            {categories.map(category => (
              <MenuItem
                key={category}
                value={category}
                className="tattoo-location-choice">
                {category}
              </MenuItem>
            ))}
          </TextField>
        ) : null}
        {formik.values.piercingLocationCategory && types && types.length > 1 ? (
          <TextField
            id="piercingLocationId"
            select
            name="piercingLocationId"
            label="Piercing Location"
            value={formik.values.piercingLocationId}
            onChange={formik.handleChange}
            error={
              formik.touched.piercingLocationId &&
              Boolean(formik.errors.piercingLocationId)
            }
            helperText={
              formik.touched.piercingLocationId &&
              formik.errors.piercingLocationId
            }>
            {types.map(type => (
              <MenuItem
                key={type.locationId}
                value={type.locationId}
                className="tattoo-location-choice">
                {type.locationType}
              </MenuItem>
            ))}
          </TextField>
        ) : null}
      </Box>

      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default SelectPiercingLocation;
