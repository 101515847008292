/* eslint-disable react/require-default-props */
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  Business,
  Employee,
  PiercingFormValues,
  Service,
  User,
} from '../../../interfaces';
import Age from './Age';
import SelectPiercingLocation from './PiercingLocation';
import ChoosePiercer from './ChoosePiercer';
import Guests from './Guests';
import Calendar from '../../Booking/Scheduler/Calendar';
import { getEmployeeById } from '../../../services/employee';
import PersonalInfo from './PersonalInfo';
import ConfirmPiercing from './ConfirmPiercing';
import BookingProgressBar from '../../Booking/General/BookingProgressBar';
import BottomActionBar from '../../Global/BottomActionBar';

const progressCategories = ['Details', 'Schedule', 'Your Info', 'Confirm'];

const formInitValues: PiercingFormValues = {
  firstName:
    sessionStorage.getItem('firstName') !== null
      ? sessionStorage.getItem('firstName')!
      : '',
  lastName:
    sessionStorage.getItem('lastName') !== null
      ? sessionStorage.getItem('lastName')!
      : '',
  email:
    sessionStorage.getItem('email') !== null
      ? sessionStorage.getItem('email')!
      : '',
  dob:
    sessionStorage.getItem('dob') !== null
      ? new Date(JSON.parse(sessionStorage.getItem('dob')!))
      : undefined,
  numberOfGuests: sessionStorage.getItem('numberOfGuests')
    ? parseInt(JSON.parse(sessionStorage.getItem('numberOfGuests')!), 10)
    : undefined,
  piercingLocationCategory:
    sessionStorage.getItem('piercingLocationCategory') !== null
      ? sessionStorage.getItem('piercingLocationCategory')!
      : '',
  piercingLocationId:
    sessionStorage.getItem('piercingLocationId') !== null
      ? parseInt(JSON.parse(sessionStorage.getItem('piercingLocationId')!), 10)
      : undefined,
  employeeId:
    sessionStorage.getItem('employeeId') !== null
      ? sessionStorage.getItem('employeeId')!
      : '',
};

type Props = {
  step: number;
  setStep: any;
  business: Business | undefined;
  user: User | undefined;
  authUser: any;
  service: Service;
};

function PiercingForm({
  step,
  setStep,
  business,
  user,
  authUser,
  service,
}: Props) {
  const [employee, setEmployee] = React.useState<Employee>();
  const [formValues, setFormValues] = React.useState(formInitValues);
  const [clickedEdit, setClickedEdit] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState(new Date());
  const [selectedDateTime, setSelectedDateTime] = React.useState(new Date());
  const [dayIsSelected, setDayIsSelected] = React.useState(false);

  const [duration, setDuration] = React.useState(30);
  const [price, setPrice] = React.useState(50);

  React.useEffect(() => {
    async function fetchEmployee() {
      const tempEmployee = await getEmployeeById(formValues.employeeId!);

      if (tempEmployee) {
        setEmployee(tempEmployee);
      }
    }

    if (formValues.employeeId) {
      fetchEmployee();
    }
  }, [formValues.employeeId]);

  React.useEffect(() => {
    let tempPrice = 50;
    let tempDuration = 30;

    if (service) {
      tempPrice = service.defaultPrice;
      tempDuration = service.defaultDurationInMinutes;
    }

    if (formValues.numberOfGuests) {
      tempPrice *= formValues.numberOfGuests;
      tempDuration *= formValues.numberOfGuests;
    }

    setPrice(tempPrice);
    setDuration(tempDuration);
  }, [service, formValues.numberOfGuests]);

  const steps = React.useMemo(
    () =>
      service.serviceType === 'REGULAR_PIERCING'
        ? ['Location', 'Piercer', 'Guests', 'Calendar', 'YourInfo', 'Confirm']
        : [
            'Age',
            'Location',
            'Piercer',
            'Guests',
            'Calendar',
            'YourInfo',
            'Confirm',
          ],
    [service.serviceType],
  );

  const backToStep = (stepName: string) => {
    const index = steps.findIndex(tempStep => tempStep === stepName);
    setStep(index + 1);
    setClickedEdit(true);
  };

  const nextStep = () => {
    if (clickedEdit) {
      backToStep('Confirm');
    } else {
      setStep(step + 1);
      sessionStorage.setItem('step', JSON.stringify(step + 1));
    }
  };

  const prevStep = () => {
    const newStep = Math.max(1, step - 1);
    setStep(newStep);
    sessionStorage.setItem('step', JSON.stringify(newStep));
  };

  React.useEffect(() => {
    if (!steps[step - 1]) {
      setStep(1);
      sessionStorage.setItem('step', JSON.stringify(1));
    }
  }, [setStep, step, steps]);

  // Render the current step component
  const renderStep = () => {
    const currentStep = Math.max(0, step - 1); // Ensure step is not negative
    switch (steps[currentStep]) {
      case 'Age':
        return (
          <>
            <BookingProgressBar
              progressCategories={progressCategories}
              currentProgress="Details"
            />
            <Age
              formValues={formValues}
              setFormValues={setFormValues}
              nextStep={nextStep}
              prevStep={prevStep}
              service={service}
            />
          </>
        );
      case 'Location':
        return (
          <>
            <BookingProgressBar
              progressCategories={progressCategories}
              currentProgress="Details"
            />
            <SelectPiercingLocation
              formValues={formValues}
              setFormValues={setFormValues}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          </>
        );
      case 'Piercer':
        return (
          <>
            <BookingProgressBar
              progressCategories={progressCategories}
              currentProgress="Details"
            />
            <ChoosePiercer
              formValues={formValues}
              setFormValues={setFormValues}
              nextStep={nextStep}
              prevStep={prevStep}
              service={service}
              business={business}
            />
          </>
        );
      case 'Guests':
        return (
          <>
            <BookingProgressBar
              progressCategories={progressCategories}
              currentProgress="Details"
            />
            <Guests
              formValues={formValues}
              setFormValues={setFormValues}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          </>
        );
      case 'Calendar':
        return (
          <Box>
            <BookingProgressBar
              progressCategories={progressCategories}
              currentProgress="Schedule"
            />
            {employee && (
              <Calendar
                duration={duration}
                employee={employee}
                selectedDay={selectedDay}
                dayIsSelected={dayIsSelected}
                setSelectedDay={setSelectedDay}
                setSelectedDateTime={setSelectedDateTime}
                setDayIsSelected={setDayIsSelected}
                nextStep={nextStep}
                service={service}
              />
            )}
            {!dayIsSelected && (
              <BottomActionBar
                secondaryText="Back"
                secondaryAction={prevStep}
              />
            )}
          </Box>
        );
      case 'YourInfo':
        return (
          <>
            <BookingProgressBar
              progressCategories={progressCategories}
              currentProgress="Your Info"
            />
            <PersonalInfo
              formValues={formValues}
              setFormValues={setFormValues}
              nextStep={nextStep}
              prevStep={prevStep}
              authUser={authUser}
              service={service}
            />
          </>
        );
      case 'Confirm':
        return (
          employee && (
            <>
              <BookingProgressBar
                progressCategories={progressCategories}
                currentProgress="Confirm"
              />
              <ConfirmPiercing
                service={service}
                employee={employee}
                selectedDateTime={selectedDateTime}
                duration={duration}
                price={price}
                prevStep={prevStep}
                formValues={formValues}
                user={user}
                backToStep={backToStep}
              />
            </>
          )
        );
      default:
        return <Typography>Error</Typography>;
    }
  };

  return <div>{renderStep()}</div>;
}

export default PiercingForm;
