import {
  addDays,
  addMinutes,
  addMonths,
  endOfMonth,
  format,
  isSameMonth,
  startOfMonth,
} from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { ServiceCategory } from '@prisma/client';
import {
  Appointment,
  AvailableTimes,
  BookingCount,
  DayItem,
  Employee,
  Service,
  Trip,
} from '../../interfaces';
import { getAvailableDays } from '../../services/availability';
import { getAllTripDates, getCityTripDates } from '../../utils/trips';
import useUser from '../global/useUser';

interface Params {
  employee: Employee;
  duration: number;
  service?: Service;
  appointment?: Appointment;
}

const NO_AVAILABLE_DAYS_IN_YEAR = 'NO_AVAILABLE_DAYS_IN_YEAR';

function useEmployeeAvailableDays({
  duration,
  employee,
  service,
  appointment,
}: Params) {
  const { user: loadedUser } = useUser();
  const [timezone] = React.useState(loadedUser?.timezone || 'US/Pacific');

  const [initialCurrentMonth, setInitialCurrentMonth] = React.useState<Date>(
    new Date(),
  );
  const [selectedMonth, setSelectedMonth] = React.useState<Date | null>(null);

  const fetchAvailableDays = React.useCallback(
    async (monthsToAdd = 0) => {
      if (!employee || !duration) return [];

      const isInitialLoad = !selectedMonth;

      const actualMonth = selectedMonth ?? addMonths(new Date(), monthsToAdd);

      // Set start and end dates to display on the calendar
      const firstOfMonth = startOfMonth(actualMonth);
      const lastOfMonth = endOfMonth(actualMonth);

      // Set available days based on onsched API call
      const response = await getAvailableDays({
        startDate: format(firstOfMonth, 'yyyy-MM-dd'),
        endDate: format(lastOfMonth, 'yyyy-MM-dd'),
        duration,
        employeeId: employee.employeeId,
        timezone,
        serviceCategory: appointment?.serviceCategory as ServiceCategory,
        onschedServiceId: service?.onschedServiceId,
        businessId: appointment?.businessId,
      });

      const isPreferredDay = (bookingCount: number, dateString: string) => {
        if (employee.stackDays) {
          if (
            bookingCount > 0 &&
            isSameMonth(actualMonth, new Date(dateString))
          ) {
            return true;
          }
        }
        return false;
      };

      const tempAvailableDays: DayItem[] = [];
      if (employee.trips && employee.trips.length > 0) {
        if (
          !appointment?.tripCity ||
          !employee.trips.some(
            (trip: Trip) => trip.city === appointment?.tripCity,
          )
        ) {
          const tripDates = getAllTripDates(employee.trips);

          let loopDate = firstOfMonth;
          while (loopDate <= lastOfMonth) {
            const loopDateString = format(loopDate, 'yyyy-MM-dd');

            if (
              response.availableTimes.some(
                (availableTime: AvailableTimes) =>
                  availableTime.date === loopDateString &&
                  new Date(availableTime.startDateTime) >=
                    addMinutes(new Date(), employee.bookInAdvanceMinutes),
              ) &&
              !tripDates.some((date: string) => date === loopDateString) &&
              new Date(loopDateString) <=
                addDays(new Date(), employee.bookAheadLimitDays)
            ) {
              tempAvailableDays.push({
                date: loopDateString,
                available: true,
                preferred: isPreferredDay(
                  response.bookingCounts?.find(
                    (item: BookingCount) => item.date === loopDateString,
                  )?.bookingCount,
                  loopDateString,
                ),
              });
            } else {
              tempAvailableDays.push({
                date: loopDateString,
                available: false,
              });
            }

            loopDate = addDays(loopDate, 1);
          }
        } else {
          const tripDates = getCityTripDates(
            appointment.tripCity,
            employee.trips,
          );

          let loopDate = firstOfMonth;
          while (loopDate <= lastOfMonth) {
            const loopDateString = format(loopDate, 'yyyy-MM-dd');

            if (
              response.availableTimes.some(
                (availableTime: AvailableTimes) =>
                  availableTime.date === loopDateString &&
                  new Date(availableTime.startDateTime) >=
                    addMinutes(new Date(), employee.bookInAdvanceMinutes),
              ) &&
              tripDates.some((date: string) => date === loopDateString)
            ) {
              tempAvailableDays.push({
                date: loopDateString,
                available: true,
                preferred: isPreferredDay(
                  response.bookingCounts?.find(
                    (item: BookingCount) => item.date === loopDateString,
                  )?.bookingCount,
                  loopDateString,
                ),
              });
            } else {
              tempAvailableDays.push({
                date: loopDateString,
                available: false,
              });
            }

            loopDate = addDays(loopDate, 1);
          }
        }
      } else {
        let loopDate = firstOfMonth;
        while (loopDate <= lastOfMonth) {
          const loopDateString = format(loopDate, 'yyyy-MM-dd');

          if (
            response.availableTimes.some(
              (availableTime: AvailableTimes) =>
                availableTime.date === loopDateString &&
                new Date(availableTime.startDateTime) >=
                  addMinutes(new Date(), employee.bookInAdvanceMinutes),
            ) &&
            new Date(loopDateString) <=
              addDays(new Date(), employee.bookAheadLimitDays)
          ) {
            tempAvailableDays.push({
              date: loopDateString,
              available: true,
              preferred: isPreferredDay(
                response.bookingCounts?.find(
                  (item: BookingCount) => item.date === loopDateString,
                )?.bookingCount,
                loopDateString,
              ),
            });
          } else {
            tempAvailableDays.push({
              date: loopDateString,
              available: false,
            });
          }

          loopDate = addDays(loopDate, 1);
        }
      }

      if (tempAvailableDays.some((day: DayItem) => day.available)) {
        return tempAvailableDays;
      }

      if (isInitialLoad) {
        const isTwelveMonthsAway = monthsToAdd >= 12;
        // If no available days in the current month, try the next month
        if (!isTwelveMonthsAway) {
          setInitialCurrentMonth(addMonths(actualMonth, 1));
          return fetchAvailableDays(monthsToAdd + 1);
        }
        throw new Error(NO_AVAILABLE_DAYS_IN_YEAR);
      }
      return [];
    },
    [
      employee,
      duration,
      selectedMonth,
      timezone,
      appointment?.serviceCategory,
      appointment?.businessId,
      appointment?.tripCity,
      service?.onschedServiceId,
    ],
  );

  const {
    data = [],
    isLoading,
    isFetching,
    isRefetching,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      'monthAvailability',
      selectedMonth,
      employee.employeeId,
      duration,
      timezone,
      appointment?.businessId,
      service?.serviceId,
    ],
    queryFn: () => fetchAvailableDays(),
    enabled: !!employee.employeeId && !!duration,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 30, // 30 seconds
    gcTime: 1000 * 60, // 60 seconds
  });

  const noAvailableDays =
    isError && error?.message === NO_AVAILABLE_DAYS_IN_YEAR && !selectedMonth;

  return {
    state: {
      selectedMonth,
      setSelectedMonth,
      initialCurrentMonth,
      setInitialCurrentMonth,
    },
    query: {
      data,
      isFetching,
      isLoading,
      isError,
      error,
      isRefetching,
      refetch,
    },
    noAvailableDays,
  };
}

export default useEmployeeAvailableDays;
