/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
import * as React from 'react';
import '../../Booking/Booking.css';
import { Box, Typography } from '@mui/material';
import { addMinutes, differenceInMonths } from 'date-fns';
import {
  Employee,
  PiercingFormValues,
  Service,
  User,
} from '../../../interfaces';
import AppointmentDetailsList from '../../Booking/General/AppointmentDetailsList';
import BottomActionBar from '../../Global/BottomActionBar';
import { createUpdateCustomer } from '../../../services/customer';
import { createPiercingAppointment } from '../../../services/appointment';
import LoadingModal from '../../Global/LoadingModal';

type Props = {
  service: Service;
  employee: Employee;
  user: User | undefined;
  selectedDateTime: Date;
  duration: number;
  price: number;
  prevStep: () => void;
  formValues: PiercingFormValues;
  backToStep: (stepName: string) => void;
};

function ConfirmPiercing({
  service,
  employee,
  user,
  selectedDateTime,
  duration,
  price,
  prevStep,
  formValues,
  backToStep,
}: Props) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);

  const title = 'Confirm your appointment';

  const modalMessage =
    'This could take a minute. Please do not refresh the page.';
  const modalHeader = 'Confirming appointment...';

  const resetState = React.useCallback(() => {
    sessionStorage.removeItem('step');
    Object.keys(formValues).forEach(key => {
      sessionStorage.removeItem(key);
    });
  }, [formValues]);

  const submitAppointment = React.useCallback(async () => {
    if (!user) {
      return;
    }

    if (!employee) {
      return;
    }

    setLoading(true);
    setModalOpen(true);

    if (service.minimumAgeInMonths && service.minimumAgeInMonths > 0) {
      if (!formValues.dob) {
        setLoading(false);
        setError(true);
        setErrorMessage('Please provide a date of birth to schedule');
        setModalOpen(false);
        return;
      }

      if (
        differenceInMonths(new Date(), formValues.dob) <
        service.minimumAgeInMonths
      ) {
        setLoading(false);
        setError(true);
        setErrorMessage(
          `Must be ${service.minimumAgeInMonths} months or older to schedule`,
        );
        setModalOpen(false);
        return;
      }
    }

    const customerResponse = await createUpdateCustomer(formValues, user).catch(
      () => {
        setError(true);
        setModalOpen(false);
        setLoading(false);
      },
    );

    if (customerResponse) {
      const appointmentResponse = await createPiercingAppointment(
        formValues,
        employee,
        user,
        service,
        price,
        duration,
        selectedDateTime,
        addMinutes(selectedDateTime, duration),
      ).catch(() => {
        setLoading(false);
        setModalOpen(false);
        setError(true);
      });

      // If the booking request was submitted, redirect the user to the new page
      if (appointmentResponse) {
        if (employee) {
          window._cio.track('Submit Booking Request', {
            artist: employee.displayName,
          });

          window._cio.identify({
            id: user.userId || user.result?.userId,
            last_appointment_id: appointmentResponse.appointmentId,
            last_employee_display_name: employee.displayName,
          });
        }

        resetState();

        window.location.replace(
          `/booking/${
            appointmentResponse.appointmentId || appointmentResponse.requestId
          }`,
        );
      } else {
        setModalOpen(false);
        setLoading(false);
      }
    }
  }, [
    user,
    employee,
    service,
    formValues,
    price,
    duration,
    selectedDateTime,
    resetState,
  ]);

  return (
    <Box>
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header">
          {title}
        </Typography>
      </Box>

      <AppointmentDetailsList
        service={service}
        employee={employee}
        aptDate={selectedDateTime}
        duration={duration}
        piercingPrice={price}
        location
        appointmentDate
        serviceType
        price
        changeDateAction={() => backToStep('Calendar')}
        changeDateText="Choose a different date"
      />

      {!loading && (
        <BottomActionBar
          primaryText="Confirm"
          primaryAction={submitAppointment}
          secondaryText="Back"
          secondaryAction={prevStep}
        />
      )}

      {loading && (
        <LoadingModal
          header={modalHeader}
          message={modalMessage}
          open={modalOpen}
        />
      )}

      {error ? (
        <Typography variant="body1" className="error">
          {errorMessage ||
            'There was an error scheduling your appointment. Please refresh the page and try again. If the error persists, reach out to our support team through the help icon above.'}
        </Typography>
      ) : null}
    </Box>
  );
}

export default ConfirmPiercing;
