/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Box, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useIdToken } from 'react-firebase-hooks/auth';
import { Business, User } from '../interfaces';
import { auth } from '../firebase';
import { getUser } from '../services/user';
import PorterFooter from '../components/Global/PorterFooter';
import { getBusinessByUrlPath } from '../services/business';
import PiercingForm from '../components/Form/Piercing/PiercingForm';
import TopBar from '../components/Global/TopBar';

export default function Piercing() {
  const navigate = useNavigate();
  const { shopUrl } = useParams();
  const location = useLocation();
  const service = location.state?.service; // Add this line

  const [loading, setLoading] = React.useState(true);
  const [authUser, authLoading] = useIdToken(auth);

  const [user, setUser] = React.useState<User>();
  const [business, setBusiness] = React.useState<Business>();

  const [step, setStep] = React.useState(1);

  const [userError, setUserError] = React.useState(false);
  const [businessError, setBusinessError] = React.useState(false);

  const desktop = useMediaQuery('(min-width:600px)');

  React.useEffect(() => {
    if (sessionStorage.getItem('step') !== null) {
      setStep(JSON.parse(sessionStorage.getItem('step')!));
    }
  }, []);

  React.useEffect(() => {
    if (!service) {
      navigate(`/shop/${shopUrl}`);
    }
  }, [navigate, service, shopUrl]);

  React.useEffect(() => {
    async function fetchData() {
      const tempBusiness = await getBusinessByUrlPath(shopUrl).catch(() => {
        setBusinessError(true);
      });

      if (tempBusiness) {
        window._cio.page('Piercing', {
          shopName: tempBusiness.name,
        });

        setBusiness({
          ...tempBusiness,
        });
      }

      setLoading(false);
    }

    if (loading) {
      fetchData();
    }
  }, [loading, shopUrl]);

  React.useEffect(() => {
    async function fetchUserData() {
      const tempUser = await getUser().catch(() => {
        setUserError(true);
      });

      if (tempUser.userId) {
        const createdAt = new Date(tempUser.createdAt);
        window.heap.identify(tempUser.userId);
        window._cio.identify({
          // Required attributes — you must include at least one of the following
          id: tempUser.userId, // Unique id for the user in your application.
          email: tempUser.email, // Email of the currently signed in user.

          created_at: Math.floor(createdAt.getTime() / 1000),
          first_name: tempUser.firstName,
          last_name: tempUser.lastName,
          phone_number: tempUser.phoneNumber,
        });
      }

      setUser({
        ...tempUser,
      });
    }

    if (authUser && !authLoading) {
      fetchUserData();
    }
  }, [authUser, authLoading]);

  switch (true) {
    // Everything is loading
    case loading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Loaded, no open request, proceed with form
    case !loading && !businessError:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title={business?.name} subtitle={service.serviceName} />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 153,
              paddingBottom: 165,
            }}>
            <PiercingForm
              step={step}
              setStep={setStep}
              business={business}
              user={user}
              authUser={authUser}
              service={service}
            />
          </Box>
          {!desktop && <PorterFooter />}
        </Box>
      );

    case businessError || userError:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this shop&apos;s page. Please double
            check that you&apos;ve entered the URL in correctly, and if the
            error persists, reach out to our support team using the help icon
            below.
          </Typography>
        </Container>
      );

    default:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this shop&apos;s page. Please double
            check that you&apos;ve entered the URL in correctly, and if the
            error persists, reach out to our support team using the help icon
            below.
          </Typography>
        </Container>
      );
  }
}
