/* eslint-disable react/require-default-props */
import { Button, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import useDetectKeyboardOpen from '../../hooks/useDetectKeyboardOpen';
import { useDetectAndroidWebView } from '../../hooks/useDetectAndroidWebView';

type Props = {
  primaryText?: string;
  secondaryText?: string;
  primaryAction?: () => void;
  secondaryAction?: () => void;
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
  isSubmit?: boolean;
  isLoading?: boolean;
};

const buttonSx: SxProps<Theme> = {
  ':disabled': {
    borderColor: 'transparent',
  },
};

function BottomActionBar({
  primaryText,
  secondaryText,
  primaryAction,
  secondaryAction,
  primaryDisabled,
  secondaryDisabled,
  isSubmit,
  isLoading,
}: Props) {
  const theme = useTheme();
  const desktop = useMediaQuery('(min-width:600px)');
  const isKeyboardOpen = useDetectKeyboardOpen();
  const isWebView = useDetectAndroidWebView();
  const hide = isWebView && isKeyboardOpen;

  return (
    <Box
      style={{
        position: 'fixed',
        bottom: desktop ? '0px' : '36px',
        display: hide ? 'none' : 'flex',
        width: !desktop ? '100%' : 568,
        flexDirection: 'column',
        gap: 10,
      }}>
      <Box
        style={{
          display: 'flex',
          width: !desktop ? 'calc(100% - 32px)' : 568,
          justifyContent: secondaryText ? 'space-between' : 'flex-end',
          padding: '20px 16px',
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: theme.palette.lightGrey.main,
          background: '#ffffff',
          zIndex: 1,
        }}>
        {secondaryText && secondaryAction && (
          <Button
            variant="outlined"
            className="secondary"
            onClick={() => secondaryAction()}
            disabled={secondaryDisabled}>
            {secondaryText}
          </Button>
        )}
        {primaryText && primaryAction && !isSubmit && (
          <LoadingButton
            variant="contained"
            className={`primary ${primaryDisabled ? 'disabled' : ''}`}
            onClick={() => primaryAction()}
            disabled={primaryDisabled}
            loading={isLoading}
            sx={buttonSx}>
            <span>{primaryText}</span>
          </LoadingButton>
        )}
        {primaryText && isSubmit && (
          <Button
            variant="contained"
            className={`primary ${primaryDisabled ? 'disabled' : ''}`}
            disabled={primaryDisabled}
            type="submit"
            sx={buttonSx}>
            {primaryText}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default BottomActionBar;
