import API_URL from '../apiconfig';
import { Business, Employee, IntakeQuestion } from '../interfaces';
import axios from './api';

interface EmployeeToBusiness {
  businessId: string;
  employeeId: string;
  isBookable: boolean;
}

export async function getBusinessById(businessId: string) {
  try {
    const business = await axios.get(`${API_URL}/v1/businesses/${businessId}`);

    return business.data.result;
  } catch (error) {
    throw new Error('Cannot get business');
  }
}

async function getEmployee(employeeId: string) {
  try {
    const employeeResult = await axios.get(
      `${API_URL}/v1/employees/${employeeId}`,
      {
        params: {
          searchBy: 'employeeId',
          shop: false,
        },
      },
    );

    return employeeResult.data.result;
  } catch (error) {
    throw new Error('Cannot get Employee');
  }
}

export async function getShopsEmployees(employees: EmployeeToBusiness[]) {
  const tempEmployees: Employee[] = [];

  try {
    await Promise.all(
      employees.map(async employeeToBusiness => {
        const tempEmployee = await getEmployee(
          employeeToBusiness.employeeId,
        ).catch(() => {});
        tempEmployees.push({
          ...tempEmployee,
          isBookable: employeeToBusiness.isBookable,
        });
      }),
    );
  } catch (error) {
    throw new Error('Cannot get Shops Employees');
  }

  return tempEmployees;
}

interface IntakeQuestionItem {
  question: IntakeQuestion;
}

export async function getBusinessByUrlPath(urlPath: string | undefined) {
  try {
    const shopResult = await axios
      .get(`${API_URL}/v1/businesses`, {
        params: {
          limit: 1,
          UrlPath: urlPath,
          artist: true,
          services: true,
        },
      })
      .catch(() => {
        throw new Error('Cannot find shop');
      });

    if (shopResult) {
      const tempShop = shopResult.data.result[0];

      const intakeQuestions = tempShop.BusinessesToQuestions.map(
        (item: IntakeQuestionItem) => ({
          questionId: item.question.questionId,
          step: item.question.step,
          category: item.question.category,
          question: item.question.question,
        }),
      );

      const employees = await getShopsEmployees(tempShop.employeesToBusinesses);

      const shop: Business = {
        businessId: tempShop.businessId,
        name: tempShop.name,
        streetAddress: tempShop.streetAddress,
        streetAddress2: tempShop.streetAddress2,
        city: tempShop.city,
        state: tempShop.state,
        zip: tempShop.zip,
        thumbnailImage: tempShop.thumbnailImage,
        coverImage: tempShop.coverImage,
        urlPath: tempShop.urlPath,
        isActive: tempShop.isActive,
        images: tempShop.images,
        faqs: tempShop.faqs,
        allowGeneralRequests: tempShop.allowGeneralRequests,
        services: tempShop.services,
        intakeQuestions,
        employees,
      };

      return shop;
    }

    return false;
  } catch (error) {
    throw new Error('Cannot get shop');
  }
}
