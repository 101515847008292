/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { FormLabel, IconButton, TextField, styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { HelpOutlineOutlined } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import '../Form.css';
import { getDate, getMonth, getYear } from 'date-fns';
import Box from '@mui/material/Box';
import { PiercingFormValues, Service } from '../../../interfaces';
import BottomActionBar from '../../Global/BottomActionBar';

type Props = {
  formValues: PiercingFormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
  service: Service;
};

const validationSchema = yup.object({
  dob: yup.date().required('Birthday is required'),
});

function Age({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
  service,
}: Props) {
  const [month, setMonth] = React.useState<string>('');
  const [year, setYear] = React.useState<string>('');
  const [day, setDay] = React.useState<string>('');

  React.useEffect(() => {
    if (formValues.dob) {
      setMonth(JSON.stringify(getMonth(formValues.dob)));
      setYear(JSON.stringify(getYear(formValues.dob)));
      setDay(JSON.stringify(getDate(formValues.dob)));
    }
  }, [formValues.dob]);

  const questionValues = {
    header: `What is the baby's date of birth?`,
    subHeader: '',
  };

  if (service.minimumAgeInMonths) {
    questionValues.subHeader = `Must be older than ${service.minimumAgeInMonths} months to get a piercing.`;
  }

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#333333',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding: '20px',
    },
  }));

  const formik = useFormik({
    initialValues: {
      dob: formValues.dob,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('dob', JSON.stringify(values.dob));
      nextStep();
    },
  });

  const setFormikValue = (
    newMonth: string | undefined,
    newYear: string | undefined,
    newDay: string | undefined,
  ) => {
    if (newMonth && newYear && newDay) {
      formik.setFieldValue(
        'dob',
        new Date(
          parseInt(newYear, 10),
          parseInt(newMonth, 10) - 1,
          parseInt(newDay, 10),
        ),
      );
      formik.setFieldTouched('dob', true, false);
    }
  };

  const changeMonth = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setMonth(event.target.value);
    setFormikValue(event.target.value, year, day);
  };

  const changeYear = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setYear(event.target.value);
    if (event.target.value.length === 4) {
      setFormikValue(month, event.target.value, day);
    }
  };

  const changeDay = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDay(event.target.value);
    setFormikValue(month, year, event.target.value);
  };

  return (
    <form onSubmit={formik.handleSubmit} id="dob">
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header sub">
          {questionValues.header}
        </Typography>
        <Typography variant="body2" className="form-header">
          {questionValues.subHeader}
        </Typography>
      </Box>

      <Box className="input-container">
        <div className="birthday-input">
          <div className="birthday-header">
            <FormLabel className="birthday-label">Birthday</FormLabel>
            <LightTooltip
              title="To submit this request, you must be at least 18 years old with a valid ID"
              arrow
              id="birthday-tooltip"
              placement="bottom-start">
              <IconButton>
                <HelpOutlineOutlined />
              </IconButton>
            </LightTooltip>
          </div>
          <div className="birthday-inputs">
            <TextField
              id="month"
              name="month"
              className="month-input"
              label="MM"
              type="text"
              value={month}
              onChange={changeMonth}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 2,
              }}
            />
            <TextField
              id="day"
              name="day"
              className="day-input"
              label="DD"
              type="text"
              value={day}
              onChange={changeDay}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 2,
              }}
            />
            <TextField
              id="year"
              name="year"
              className="year-input"
              label="YYYY"
              type="text"
              value={year}
              onChange={changeYear}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 4,
                minLength: 4,
              }}
            />
          </div>
        </div>
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default Age;
