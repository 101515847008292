import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  getCountryCallingCode,
  isPossiblePhoneNumber,
  Country,
} from 'react-phone-number-input';
import BottomActionBar from '../Global/BottomActionBar';
import { LoginValues } from '../../types/auth';
import useSignIn from '../../hooks/global/useSignIn';

type Props = {
  loginValues: LoginValues;
  setLoginValues: React.Dispatch<React.SetStateAction<LoginValues>>;
};

function Phone({ loginValues, setLoginValues }: Props) {
  const [value, setValue] = React.useState<string>();
  const [selectedCountry, setSelectedCountry] = React.useState<Country>('US');

  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const header = "What's your phone number?";
  const desktop = useMediaQuery('(min-width:600px)');

  const valid = !!value && isPossiblePhoneNumber(value);

  const signInMutation = useSignIn({
    onSuccess: response => {
      if ('error' in response) {
        setErrorMessage(response.error);
        setError(true);
        return;
      }

      setLoginValues(prev => ({
        ...prev,
        step: prev.step + 1,
        userPhone: response.phone,
        confirmationResult: response.confirmation,
      }));
      setError(false);
    },
  });

  const handleChange = React.useCallback((val: string) => {
    setValue(val);
  }, []);

  const handleSubmit = React.useCallback(
    (event?: React.FormEvent<HTMLFormElement>) => {
      event?.preventDefault(); // Prevent the default form submission behavior
      signInMutation.mutate(value); // Call the sendCode function to handle the logic
    },
    [signInMutation, value],
  );

  return (
    <form
      style={{ marginTop: desktop ? 63 : 84 }}
      id="login-form"
      onSubmit={handleSubmit}>
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header">
          {header}
        </Typography>
        <Typography variant="body2" className="form-sub-header">
          To access your appointment, please verify your phone number.
        </Typography>
      </Box>

      <Box className="input-container">
        <div className="phone-input-container">
          <span className="country-code">
            +{getCountryCallingCode(selectedCountry)}
          </span>
          <PhoneInput
            defaultCountry="US"
            onCountryChange={country => {
              if (country) {
                setSelectedCountry(country);
              }
            }}
            value={value}
            onChange={handleChange}
            countrySelectProps={{ unicodeFlags: true }}
            placeholder="Phone number"
          />
        </div>
        <Typography variant="body2" className="tos">
          By logging in, you acknowledge that you have read, understood, and
          agree to our{' '}
          <Link href="https://getporter.io/terms-of-use" target="_blank">
            Terms of Use
          </Link>
          .
        </Typography>
        {error ? (
          <Typography variant="body1" color="primary" className="error">
            {errorMessage}
          </Typography>
        ) : null}
      </Box>
      <BottomActionBar
        primaryText="Continue"
        primaryDisabled={!valid || signInMutation.isPending}
        primaryAction={() => handleSubmit()}
      />
    </form>
  );
}

export default Phone;
