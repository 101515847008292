import { createTheme } from '@mui/material';

// Augment the palette to include an porterGrey color
declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
    darkGrey: Palette['primary'];
    medGrey: Palette['primary'];
    lightGrey: Palette['primary'];
    outlineGrey: Palette['primary'];
    lightOutlineGrey: Palette['primary'];
    inputGreyOutline: Palette['primary'];
  }

  interface PaletteOptions {
    accent?: PaletteOptions['primary'];
    darkGrey?: PaletteOptions['primary'];
    medGrey?: PaletteOptions['primary'];
    lightGrey?: PaletteOptions['primary'];
    outlineGrey?: PaletteOptions['primary'];
    lightOutlineGrey?: PaletteOptions['primary'];
    inputGreyOutline?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an porterGrey option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true;
    darkGrey: true;
    medGrey: true;
    lightGrey: true;
    outlineGrey: true;
    lightOutlineGrey: true;
    inputGreyOutline: true;
  }
  interface ButtonPropsVariantOverrides {
    smallLink: true;
    link: true;
    light: true;
    base: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Open Sans',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#FF3008',
    },
    secondary: {
      main: '#00A86B',
    },
    accent: {
      main: '#CEEEE3',
      contrastText: '#000000',
    },
    darkGrey: {
      main: '#333333',
      contrastText: '#FFFFFF',
    },
    medGrey: {
      main: '#666666',
      contrastText: '#FFFFFF',
    },
    outlineGrey: {
      main: '#939393',
      contrastText: '#FFFFFF',
    },
    lightGrey: {
      main: '#F5F5F5',
      contrastText: '#000000',
    },
    lightOutlineGrey: {
      main: '#bdbdbd',
      contrastText: '#000000',
    },
    inputGreyOutline: {
      main: 'rgba(66, 87, 138, 0.15)',
      contrastText: '#000000',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'base' },
          style: ({ theme: currentTheme }) => ({
            color: '#ffffff',
            backgroundColor: currentTheme.palette.primary.main,
            fontSize: '0.875rem',
            fontWeight: '500',
            padding: '0.75rem 1rem',
            borderRadius: '10px',
            border: 'none',
            ':disabled': {
              opacity: 0.8,
              color: currentTheme.palette.lightGrey.main,
            },
            ':hover': {
              backgroundColor: '#c30000',
            },
          }),
        },
        {
          props: { variant: 'smallLink' },
          style: ({ theme: currentTheme }) => ({
            color: currentTheme.palette.darkGrey.main,
            fontSize: currentTheme.typography.body2.fontSize,
            fontWeight: '400',
            textDecoration: 'underline',
            padding: 0,
            ':hover': {
              backgroundColor: 'transparent',
            },
          }),
        },
        {
          props: { variant: 'link' },
          style: {
            color: '#000000',
            fontSize: '0.875rem',
            fontWeight: '700',
            textDecoration: 'underline',
            padding: 0,
            ':hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'light' },
          style: ({ theme: currentTheme }) => ({
            color: currentTheme.palette.darkGrey.main,
            fontSize: '0.875rem',
            fontWeight: '500',
            padding: '0.75rem 1rem',
            borderRadius: '10px',
            border: `1px solid ${currentTheme.palette.lightOutlineGrey.main}`,
            ':disabled': {
              backgroundColor: currentTheme.palette.lightGrey.main,
              color: currentTheme.palette.medGrey.main,
              opacity: 0.8,
            },
          }),
        },
      ],
    },
  },
});

theme.typography.h1 = {
  fontSize: '1.625rem',
  fontWeight: '700',
  textAlign: 'left',
};

theme.typography.h2 = {
  fontSize: '1.375rem',
  fontWeight: '700',
  textAlign: 'left',
  color: '#333333',
};

theme.typography.h3 = {
  fontSize: '1rem',
  fontWeight: '700',
  textAlign: 'left',
  color: '#333333',
};

theme.typography.h4 = {
  fontSize: '.6875rem',
  fontWeight: '700',
  textAlign: 'left',
  color: '#333333',
  textTransform: 'uppercase',
};

theme.typography.h5 = {
  fontSize: '.9rem',
  fontWeight: '600',
  textAlign: 'left',
  color: '#333333',
};

theme.typography.button = {
  fontSize: '1rem',
  fontWeight: '700',
  lineHeight: 'auto',
};

theme.typography.body1 = {
  fontSize: '1.125rem',
  fontWeight: '400',
  textAlign: 'left',
  color: '#333333',
};

theme.typography.body2 = {
  fontSize: '.875rem',
  fontWeight: '400',
  textAlign: 'left',
  color: '#333333',
};

export default theme;
