import * as React from 'react';
import '../Booking.css';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import { loadStripe } from '@stripe/stripe-js';
import { Appointment, Business, Employee } from '../../../interfaces';
import PaymentMethods from '../Checkout/PaymentMethod';
import { closeCheckout, validateCheckout } from '../../../services/appointment';
import LoadingModal from '../../Global/LoadingModal';
import AddNewPaymentMethod from '../Checkout/AddNewPaymentMethod';
import useCheckoutSession from '../../../hooks/checkout/useCheckoutSession';
import useDebouncedState from '../../../hooks/useDebouncedState';
import usePaymentMethods from '../../../hooks/checkout/usePaymentMethods';
import { getAppointmentPriceDetails } from '../../../utils/appointment';
import STRIPE_PK from '../../../stripe';
import SubmitButton from '../Checkout/SubmitButton';

type Props = {
  appointment: Appointment;
  employee: Employee;
  business: Business | undefined;
  loading: boolean;
};

type Tip = 15 | 20 | 25 | 'custom';

const DEFAULT_TIP = 0.2;

const stripePromise = loadStripe(STRIPE_PK);

const getInitialTip = (appointment: Appointment) => {
  const { depositCents, depositPaid, appointmentPriceCents } =
    getAppointmentPriceDetails(appointment);
  const total = depositPaid
    ? appointmentPriceCents + depositCents
    : appointmentPriceCents;
  return Math.round(total * DEFAULT_TIP);
};

function Checkout({ appointment, employee, business, loading }: Props) {
  const [activeTip, setActiveTip] = React.useState<Tip>(20);
  const [tipAmtCents, debouncedTipCents, setTipAmtCents] = useDebouncedState(
    getInitialTip(appointment),
    activeTip === 'custom' ? 500 : 0,
  );

  const {
    checkoutSession,
    isFetching,
    isError,
    isLoading: isLoadingSession,
  } = useCheckoutSession({
    appointment,
    businessId: business?.businessId,
    tipAmountCents: debouncedTipCents || 0,
    enabled: !loading,
  });
  const {
    appointmentPriceCents = 0,
    depositCents = 0,
    isDepositPaid,
    salesTaxCents = 0,
    serviceFeeCents = 0,
    totalPriceCents = 0,
    paymentIntent,
  } = checkoutSession || {};

  const [submitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [success, setSuccess] = React.useState(false);

  const [needsPaymentInfo, setNeedsPaymentInfo] = React.useState(false);
  const { paymentMethods, isFetching: isFetchingPaymentMethods } =
    usePaymentMethods({
      onSuccess: pms => {
        setNeedsPaymentInfo(!pms.length);
      },
    });
  const paymentMethod = paymentMethods?.[0];

  const isLoading =
    isFetching || debouncedTipCents !== tipAmtCents || isFetchingPaymentMethods;
  const isCheckoutDisabled =
    isLoading ||
    !totalPriceCents ||
    submitting ||
    isError ||
    !checkoutSession?.paymentIntent?.client_secret;

  const modalMessage =
    'This could take a minute. Please do not refresh the page.';
  const modalHeader = 'Processing payment...';
  const successMessage = 'Payment completed!';
  const artistName = employee.displayName
    ? employee.displayName
    : `${employee.user.firstName} ${employee.user.lastName}`;

  const changeTip = (percent: Tip) => {
    const appointmentPrice = isDepositPaid
      ? appointmentPriceCents + depositCents
      : appointmentPriceCents;

    const multiplier = typeof percent === 'number' ? percent / 100 : 0;
    const newTipAmount = Math.round(appointmentPrice * multiplier);
    setActiveTip(percent);

    setTipAmtCents(Math.max(0, newTipAmount));
  };

  const processPayment = React.useCallback(async () => {
    const stripe = await stripePromise;

    if (
      isLoading ||
      isError ||
      !stripe ||
      !paymentIntent?.client_secret ||
      !checkoutSession ||
      !appointment?.appointmentId ||
      isCheckoutDisabled
    )
      return;

    setSubmitting(true);
    setErrorMessage('');

    const isValid = await validateCheckout({
      appointmentId: appointment.appointmentId,
      previousCheckoutSession: checkoutSession,
      tipAmountCents: tipAmtCents,
      businessId: business?.businessId,
    });
    if (!isValid) {
      setSubmitting(false);
      setErrorMessage(
        'This appointment is no longer available for checkout. Please refresh the page and try again.',
      );
      return;
    }

    if (
      appointmentPriceCents + serviceFeeCents + tipAmtCents + salesTaxCents !==
      totalPriceCents
    ) {
      const tempErrorMessage =
        'Error processing transaction. Please refresh the page and try again. You have not been charged.';
      setSubmitting(false);
      setErrorMessage(tempErrorMessage);
      return;
    }

    const result = await stripe.confirmCardPayment(
      paymentIntent.client_secret,
      {
        payment_method: paymentMethod?.id,
      },
    );
    if (result.error) {
      setSubmitting(false);
      setErrorMessage(
        result.error.message ||
          'An unexpected error occured. You have not been charged. Please try again or reach out to our support team for assistance',
      );
      return;
    }

    if (result.paymentIntent?.next_action?.redirect_to_url?.url) {
      window.location.replace(
        result.paymentIntent.next_action.redirect_to_url.url?.toString(),
      );
    }

    if (paymentIntent) {
      setSuccess(true);

      const totalPrice = appointmentPriceCents / 100;
      const tipPrice = tipAmtCents / 100;
      await closeCheckout(
        appointment.appointmentId!,
        totalPrice,
        tipPrice,
      ).then(() => {
        setSuccess(true);
        setTimeout(() => {
          window.location.replace(`/booking/${appointment.appointmentId}`);
        }, 2000);
      });
    }
  }, [
    isLoading,
    isError,
    paymentIntent,
    checkoutSession,
    appointment.appointmentId,
    tipAmtCents,
    business?.businessId,
    appointmentPriceCents,
    serviceFeeCents,
    salesTaxCents,
    totalPriceCents,
    paymentMethod?.id,
    isCheckoutDisabled,
  ]);

  return (
    <Box
      style={{
        flex: '1 1 0',
        marginTop: 63,
      }}>
      <div className="checkout-flex">
        <div className="checkout-header">
          <Avatar alt={artistName} src={employee.profileImage} />
          <Typography variant="h3">{artistName}</Typography>
          {business && <Typography variant="body1">{business.city}</Typography>}
        </div>
        <div className="checkout-table">
          <div className="checkout-row">
            <Typography variant="body2" className="grow">
              {appointment.tattooLocation
                ? `${appointment.tattooLocation} tattoo`
                : 'Tattoo'}
            </Typography>
            <Typography variant="body2">
              ${(appointment.price || 0)?.toFixed(2)}
            </Typography>
          </div>
        </div>
        {isLoadingSession && (
          <div className="checkout-loading">
            <CircularProgress
              sx={{
                marginTop: 3,
              }}
            />
          </div>
        )}
        {!isLoadingSession && (
          <>
            <div className="checkout-tips">
              <div className="tip-summary">
                <Typography variant="h4">Artist tip:</Typography>
                <Typography variant="h4">
                  ${(tipAmtCents / 100).toFixed(2)}
                </Typography>
              </div>
              <div className="tip-suggestions">
                <Button
                  className={`tip-box ${activeTip === 15 ? 'active' : null}`}
                  onClick={() => changeTip(15)}>
                  <Typography variant="h4">15%</Typography>
                </Button>
                <Button
                  className={`tip-box ${activeTip === 20 ? 'active' : null}`}
                  onClick={() => changeTip(20)}>
                  <Typography variant="h4">20%</Typography>
                </Button>
                <Button
                  className={`tip-box ${activeTip === 25 ? 'active' : null}`}
                  onClick={() => changeTip(25)}>
                  <Typography variant="h4">25%</Typography>
                </Button>
                <Button
                  className={`tip-box ${
                    activeTip === 'custom' ? 'active' : null
                  }`}
                  onClick={() => changeTip('custom')}>
                  <Typography variant="h4">Other</Typography>
                </Button>
              </div>
              <Typography variant="body2" color="var(--medGrey)">
                100% of your tip goes to {artistName}
              </Typography>
              {activeTip === 'custom' && (
                <NumericFormat
                  placeholder="Tip Amount"
                  name="tip"
                  className="tip-input"
                  thousandSeparator=","
                  allowNegative={false}
                  customInput={TextField}
                  decimalScale={2}
                  value={tipAmtCents / 100}
                  prefix="$"
                  onValueChange={(values: any) => {
                    if (Number(values.value)) {
                      setTipAmtCents(parseInt(values.value, 10) * 100);
                      return;
                    }
                    setTipAmtCents(0);
                  }}
                />
              )}
            </div>
            <div className="checkout-table">
              <div className="checkout-row">
                <Typography variant="body2" className="grow">
                  Subtotal
                </Typography>
                <Typography variant="body2">
                  ${appointment.price?.toFixed(2)}
                </Typography>
              </div>
              <div className="checkout-row">
                <Typography variant="body2" className="grow">
                  Tip
                </Typography>
                <Typography variant="body2">
                  ${(tipAmtCents / 100).toFixed(2)}
                </Typography>
              </div>
              {(salesTaxCents > 0 || serviceFeeCents > 0) && (
                <div className="checkout-row">
                  <Typography variant="body2" className="grow">
                    {salesTaxCents ? 'Taxes & Fees' : 'Service Fee'}
                  </Typography>
                  <Typography variant="body2">
                    {isFetching
                      ? '-'
                      : `$${(
                          ((salesTaxCents || 0) + serviceFeeCents) /
                          100
                        ).toFixed(2)}`}
                  </Typography>
                </div>
              )}
              {isDepositPaid && (
                <div className="checkout-row">
                  <Typography variant="body2" className="grow">
                    Deposit paid
                  </Typography>
                  <Typography variant="body2" color="red">
                    &#40;${(depositCents / 100).toFixed(2)}&#41;
                  </Typography>
                </div>
              )}
              <div className="checkout-row">
                <Typography variant="h4" className="grow">
                  TOTAL DUE
                </Typography>
                {totalPriceCents && (
                  <Typography variant="h4">
                    {isFetching ? (
                      <CircularProgress size={10} />
                    ) : (
                      `$${(totalPriceCents / 100)?.toFixed(2)}`
                    )}
                  </Typography>
                )}
              </div>
            </div>
            {errorMessage ? (
              <Typography variant="body1" className="error">
                {errorMessage}
              </Typography>
            ) : null}
            {!needsPaymentInfo && (
              <PaymentMethods
                paymentMethod={paymentMethod}
                setNeedsPaymentMethod={setNeedsPaymentInfo}
              />
            )}
            {needsPaymentInfo && (
              <AddNewPaymentMethod
                appointment={appointment}
                checkoutSession={checkoutSession ?? null}
                tipPriceCents={tipAmtCents}
                setErrorMessage={setErrorMessage}
                setSubmitting={setSubmitting}
                setSuccess={setSuccess}
                business={business}
                disabledCheckout={isCheckoutDisabled}
              />
            )}
          </>
        )}
      </div>
      {!needsPaymentInfo && !isLoadingSession && (
        <div className="footer-sticky">
          <SubmitButton
            loading={submitting}
            disabled={!paymentMethod || isCheckoutDisabled}
            onSubmit={processPayment}>
            Pay ${((totalPriceCents || 0) / 100)?.toFixed(2)}
          </SubmitButton>
          <Typography variant="body2" className="center">
            Powered by Porter
          </Typography>
        </div>
      )}
      {submitting && (
        <LoadingModal
          header={modalHeader}
          message={modalMessage}
          open={submitting}
          success={success}
          successMessage={successMessage}
        />
      )}
    </Box>
  );
}

export default Checkout;
