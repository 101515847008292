import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import useWaiver from '../../../hooks/waiver/useWaiver';
import { Appointment, Business } from '../../../interfaces';
import ConfirmCustomerDetails from './ConfirmCustomerDetails';
import StaticLiabilityWaiver from './StaticLiabilityWaiver';
import Acknowledgments from './Acknowledgments';
import MedicalHistory from './MedicalHistory';
import IDUpload from './IDUpload';
import CompleteAndSign from './CompleteAndSign';
import { WaiverForm, WaiverSectionProps } from '../../../types/waivers';
import { getInitialWaiverForm, storeWaiverForm } from '../../../utils/waivers';
import useUser from '../../../hooks/global/useUser';
import useSubmitWaiver from '../../../hooks/waiver/useSubmitWaiver';
import { FormHeaderAlert } from '../../Form/FormHeader';
import useIOSVersion from '../../../hooks/global/useIOSVersion';

type Props = {
  business: Business | undefined;
  onSuccess?: () => void | Promise<void>;
  handleBack?: () => void;
} & (
  | {
      type: 'appointment';
      appointment: Appointment | undefined;
    }
  | {
      type: 'preview';
    }
);

interface WaiverStep {
  component: React.ComponentType<WaiverSectionProps>;
}

const PREVIEW_ALERT: FormHeaderAlert = {
  title: 'This is a waiver preview',
  subtitle:
    "Do not share this link with customers - this is just intended for you to preview the waiver. Customers will be sent their own, personalized waiver link when it's time for them to complete a waiver.",
  type: 'info',
  dismissible: true,
  animate: false,
  persistentDismissAlertKey: 'waiver-preview-alert',
};

export default function Waiver({
  business,
  onSuccess,
  handleBack,
  ...props
}: Props) {
  const iosVersion = useIOSVersion();

  const [step, setStep] = React.useState(0);
  const { user } = useUser();

  const appointment =
    props.type === 'appointment' ? props.appointment : undefined;
  const isPreview = props.type === 'preview';

  const { waiver, previewWaiverId } = useWaiver({ appointment, isPreview });

  if (iosVersion && iosVersion.major < 17) {
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    if (typeof Promise.withResolvers === 'undefined') {
      if (window)
        // @ts-expect-error This does not exist outside of polyfill which this is doing
        window.Promise.withResolvers = () => {
          let resolve;
          let reject;
          const promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
          });
          return { promise, resolve, reject };
        };
    }
  }

  const handleSuccess = onSuccess ?? (() => window.location.reload());

  const { submitWaiverMutation } = useSubmitWaiver({
    appointment,
    business,
    onSuccess: handleSuccess,
  });

  const [waiverForm, setWaiverForm] = React.useState<WaiverForm | null>(null);

  const storageIdentifier = isPreview
    ? `${previewWaiverId}-preview`
    : appointment?.appointmentId;

  React.useEffect(() => {
    const getWaiverForm = async () => {
      if (waiver && (user || isPreview) && !waiverForm && storageIdentifier) {
        setWaiverForm(
          await getInitialWaiverForm(
            user,
            waiver,
            storageIdentifier,
            isPreview,
          ),
        );
      }
    };
    getWaiverForm();
  }, [waiver, user, waiverForm, storageIdentifier, isPreview]);

  React.useEffect(() => {
    if (waiver && (user || isPreview) && waiverForm && storageIdentifier) {
      storeWaiverForm(user, waiver, storageIdentifier, waiverForm);
    }
  }, [waiver, user, waiverForm, storageIdentifier, isPreview]);

  const steps = [
    { component: ConfirmCustomerDetails },
    waiver?.staticWaiverSettings?.enabled && {
      component: StaticLiabilityWaiver,
    },
    waiver?.acknowledgmentSettings?.enabled && { component: Acknowledgments },
    waiver?.medicalHistorySettings?.enabled && { component: MedicalHistory },
    waiver?.identificationSettings?.enabled && { component: IDUpload },
    { component: CompleteAndSign },
  ].filter(Boolean) as WaiverStep[];

  const nextStep = () => {
    if (!waiver) return;
    setStep(prev => {
      const newStep = Math.min(prev + 1, steps.length - 1);
      if (newStep !== prev) {
        window.scrollTo(0, 0);
      }
      return newStep;
    });
  };
  const prevStep = () => setStep(prev => Math.max(prev - 1, 0));

  const onSubmit = async (finalSignature: string) => {
    if (!waiverForm) return;
    if (appointment && props.type === 'appointment') {
      await submitWaiverMutation.mutateAsync({
        ...waiverForm,
        signature: finalSignature,
      });
    }
    if (isPreview) {
      toast.success('Preview waiver completed!');
    }
  };

  const CurrentStep = steps[step]?.component;

  if (!waiverForm || !waiver || (!appointment && !isPreview) || !business) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={300}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box>
      <CurrentStep
        alert={isPreview ? PREVIEW_ALERT : undefined}
        isPreview={isPreview}
        employee={{
          firstName: isPreview
            ? 'John'
            : appointment?.employee?.firstName ?? '',
          lastName: isPreview
            ? 'Doe (Artist)'
            : appointment?.employee?.lastName ?? '',
        }}
        business={business}
        waiver={waiver}
        onNext={nextStep}
        onBack={step === 0 ? handleBack : prevStep}
        onSubmit={onSubmit}
        waiverForm={{
          value: waiverForm,
          set: setWaiverForm as React.Dispatch<
            React.SetStateAction<WaiverForm>
          >,
        }}
      />
    </Box>
  );
}
