import PageLayout from '../../Global/PageLayout';
import WaiverOptionsList from './WaiverOptionsList';
import { WaiverSectionProps } from '../../../types/waivers';

export default function Acknowledgments({
  waiver,
  onNext,
  onBack,
  waiverForm,
  alert,
}: WaiverSectionProps) {
  const isValid = Object.values(waiverForm.value.acknowledgments).every(
    Boolean,
  );

  return (
    <PageLayout
      alert={alert}
      header="Acknowledgments"
      subheader={waiver?.acknowledgmentSettings?.intro}
      onNext={onNext}
      onBack={onBack}
      nextDisabled={!isValid}>
      <WaiverOptionsList
        options={waiver?.acknowledgmentSettings?.options}
        type="acknowledgments"
        waiverForm={waiverForm}
      />
    </PageLayout>
  );
}
