import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import GoogleIcon from '@mui/icons-material/Google';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ReviewStatus } from '@prisma/client';
import { Business } from '../interfaces';
import { getBusinessById } from '../services/business';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import { logReviewLinkVisit, postReview } from '../services/appointment';

export default function ReviewPage() {
  const desktop = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();

  const [business, setBusiness] = React.useState<Business>();
  const { reviewLinkId } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [errorPosting, setErrorPosting] = React.useState(false);
  const [feedbackPosted, setFeedbackPosted] = React.useState(false);
  const [goodExperience, setGoodExperience] = React.useState<
    'true' | 'false'
  >();
  const [feedback, setFeedback] = React.useState<string>();
  const [isComplete, setIsComplete] = React.useState(false);

  React.useEffect(() => {
    async function logVisitFetchBusiness() {
      try {
        const tempReviewLink = await logReviewLinkVisit(reviewLinkId!);
        if (!tempReviewLink?.businessId) {
          throw new Error('Review link not found');
        }

        const tempShop = await getBusinessById(tempReviewLink.businessId);

        if (tempShop) {
          setBusiness({
            ...tempShop,
          });

          if (!tempShop.reviewLink) {
            navigate(`/shop/${tempShop.urlPath}`);
          }
        }

        if (tempReviewLink.reviewStatus === ReviewStatus.COMPLETED) {
          setIsComplete(true);
        }
      } catch {
        setError(true);
      }
      setLoading(false);
    }

    if (loading && reviewLinkId) {
      logVisitFetchBusiness();
    }
  }, [loading, navigate, reviewLinkId]);

  React.useEffect(() => {
    async function createReview() {
      try {
        await postReview(reviewLinkId!, 5);
        setFeedbackPosted(true);
      } catch {
        setErrorPosting(true);
      }
    }

    if (goodExperience && goodExperience === 'true' && reviewLinkId) {
      createReview();
    }
  }, [goodExperience, reviewLinkId]);

  const logNegativeReview = async () => {
    try {
      await postReview(reviewLinkId!, 1, feedback);
      setFeedbackPosted(true);
    } catch {
      setErrorPosting(true);
    }
  };

  if (loading)
    return (
      <Container maxWidth="lg" className="data-error">
        <Typography variant="body1">Loading...</Typography>
      </Container>
    );

  return (
    <Container
      maxWidth="md"
      className="shop-page"
      style={{ padding: 0, height: '100svh', display: 'flex' }}>
      {!error && (
        <Box className="review-form">
          {!isComplete && (
            <>
              {!goodExperience && (
                <>
                  <Box className="review-header">
                    <div className="review-location">
                      <LocationOnIcon style={{ color: 'var(--medGrey)' }} />
                      <Typography
                        variant="h5"
                        style={{ color: 'var(--medGrey)' }}>
                        {business?.name}
                      </Typography>
                    </div>
                    <Typography variant="h1">
                      Did you have a good experience at {business?.name}?
                    </Typography>
                    <Typography variant="body2">
                      We appreciate your feedback so we can continue to improve
                      our experience!
                    </Typography>
                  </Box>
                  <RadioGroup
                    aria-labelledby="radio-goodExperience"
                    name="goodExperience"
                    id="good-experience-radio"
                    value={goodExperience}
                    onChange={event =>
                      setGoodExperience(
                        event.target.value as NonNullable<
                          typeof goodExperience
                        >,
                      )
                    }
                    className="radio-box">
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio
                          className="radio-input"
                          color="secondary"
                          inputProps={{ className: 'radio-first-tattoo' }}
                        />
                      }
                      label="Yes"
                      className={`radio-pill ${
                        goodExperience === 'true' ? 'checked' : ''
                      }`}
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio
                          className="radio-input"
                          color="secondary"
                          inputProps={{ className: 'radio-first-tattoo' }}
                        />
                      }
                      label="No"
                      className={`radio-pill ${
                        goodExperience === 'false' ? 'checked' : ''
                      }`}
                    />
                  </RadioGroup>
                </>
              )}
              {goodExperience === 'true' && (
                <>
                  <Box className="review-header">
                    <div className="review-location">
                      <LocationOnIcon style={{ color: 'var(--medGrey)' }} />
                      <Typography
                        variant="body2"
                        style={{ color: 'var(--medGrey)' }}>
                        {business?.name}
                      </Typography>
                    </div>
                    <Typography variant="h1">
                      {business?.name} would appreciate if you review their
                      business below
                    </Typography>
                    <Typography variant="body2">
                      Submit your review on Google below!
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    className="primary"
                    href={business?.reviewLink}
                    style={{
                      backgroundColor: '#4285F4',
                      color: '#ffffff',
                      border: 'none',
                      boxShadow: 'none',
                      fontSize: '.9rem',
                    }}
                    startIcon={<GoogleIcon />}>
                    Review on Google
                  </Button>
                </>
              )}
              {goodExperience === 'false' && !feedbackPosted && (
                <>
                  <Box className="review-header">
                    <div className="review-location">
                      <LocationOnIcon style={{ color: 'var(--medGrey)' }} />
                      <Typography
                        variant="body2"
                        style={{ color: 'var(--medGrey)' }}>
                        {business?.name}
                      </Typography>
                    </div>
                    <Typography variant="h1">
                      How could we improve your experience?
                    </Typography>
                    <Typography variant="body2">
                      We really appreciate you taking the time to let us know
                      how we can improve!
                    </Typography>
                  </Box>
                  <TextField
                    id="feedback"
                    name="feedback"
                    value={feedback}
                    onChange={event => setFeedback(event.target.value)}
                    multiline
                    minRows={3}
                    className="textarea"
                  />
                  <Button
                    variant="contained"
                    className="primary"
                    onClick={logNegativeReview}>
                    Submit Feedback
                  </Button>
                </>
              )}
              {goodExperience === 'false' && feedbackPosted && (
                <Box className="review-header">
                  <div className="review-location">
                    <LocationOnIcon style={{ color: 'var(--medGrey)' }} />
                    <Typography
                      variant="body2"
                      style={{ color: 'var(--medGrey)' }}>
                      {business?.name}
                    </Typography>
                  </div>
                  <Typography variant="h1">
                    Thanks for your feedback!
                  </Typography>
                  <Typography variant="body2">
                    We value and appreciate your feedback and hope that we can
                    resolve any issues you had!
                  </Typography>
                </Box>
              )}
              {errorPosting && (
                <Typography variant="body2" className="error">
                  Error logging feedback. Please refresh the page and try again
                </Typography>
              )}
            </>
          )}
          {isComplete && (
            <Box className="review-header">
              <div className="review-location">
                <LocationOnIcon style={{ color: 'var(--medGrey)' }} />
                <Typography variant="body2" style={{ color: 'var(--medGrey)' }}>
                  {business?.name}
                </Typography>
              </div>
              <Typography variant="h1">Thanks for your feedback!</Typography>
              <Typography variant="body2">
                {/* neutral - we don't know if good or bad */}
                We value and appreciate your feedback and hope that you had a
                great experience!
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {error && (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              Error loading this review page. Please ensure you entered the
              review URL correctly.
            </Typography>
          </Box>
          {!desktop && <PorterFooter />}
        </Box>
      )}
    </Container>
  );
}
